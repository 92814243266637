import React from 'react';
import BadgeStatus from '../../components/elements/BadgeStatus';

export const FAILED = 'ListRegistrasiMerchant/failed';
export const LOADING = 'ListRegistrasiMerchant/loading';
export const SUCCESS = 'ListRegistrasiMerchant/success';
export const SET_DATA = 'ListRegistrasiMerchant/set_data';
export const SET_FILTER = 'ListRegistrasiMerchant/set_filter';

export const processStatus = {
  'Perlu Diproses': <BadgeStatus label="Perlu Diproses"/>,
  'Sedang Diproses': <BadgeStatus label="Sedang Diproses"/>,
  'Disetujui': <BadgeStatus label="Disetujui"/>,
  'Ditolak': <BadgeStatus label="Ditolak"/>,
};

export const settingStatus = {
  'ACTIVE': <BadgeStatus label="Aktif"/>,
  'NON_ACTIVE': <BadgeStatus label="Tidak Aktif"/>
};

