export const FAILED = 'permission/failed';
export const LOADING = 'permission/loading';
export const SUCCESS = 'permission/success';
export const SET_DATA = 'permission/set_data';
export const DOWNLOAD_FAILED = 'user/download/failed';
export const DOWNLOAD_LOADING = 'user/download/loagding';
export const DOWNLOAD_SUCESS = 'user/download/successs';

export const actionTypes = {
  FILTER: 'auditLog/filterAudit',
};
