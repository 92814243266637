export const FAILED = 'SettingHarga/failed';
export const LOADING = 'SettingHarga/loading';
export const SUCCESS = 'SettingHarga/success';
export const SET_DATA = 'ListProduct/set_data';

export const validate = (values) => {
  const errors = {};
  if (!values.hargaJualMin) {
    errors.hargaJualMin = { _error: 'Harga Jual Minimum harus diisi' };
  }
  if (!values.hargaJualMax) {
    errors.hargaJualMax = { _error: 'Harga Jual Maksimum harus diisi' };
  }

  if(parseFloat(values.hargaJualMin) <= 0){
    errors.hargaJualMin = 'Harga Jual Minimum harus diatas 0';
  }

  if(parseFloat(values.hargaJualMax) <= 0){
    errors.hargaJualMax = 'Harga Jual Maksimum harus diatas 0';
  }

  if (parseFloat(values.hargaJualMin) > parseFloat(values.hargaJualMax)) {
    errors.hargaJualMin = 'harus lebih kecil dari harga maksimum';
    errors.hargaJualMax = 'harus lebih besar dari harga minimum';
  }
  return errors;
};
