export const FAILED = 'ListRegistrasiMerchant/failed';
export const LOADING = 'ListRegistrasiMerchant/loading';
export const SUCCESS = 'ListRegistrasiMerchant/success';
export const SET_DATA = 'ListRegistrasiMerchant/set_data';
export const SET_FILTER = 'ListRegistrasiMerchant/set_filter';

export const processStatus = {
  all: 'Semua',
  PENDING: 'Perlu Diproses',
  ONPROCESS: 'Sedang Diproses',
  APPROVED: 'Disetujui',
  REJECTED: 'Ditolak',
};

export const processColor = {
  PENDING: 'warning',
  ONPROCESS: 'info',
  APPROVED: 'success',
  REJECTED: 'danger',
};

export const merchantInformations = [
  { label: 'Tipe Merchant', value: 'merchantTypeName' },
  {
    label: 'Alamat Merchant',
    value: 'address',
  },
  {
    label: 'Provinsi',
    value: 'provinceName',
  },
  {
    label: 'Kota/Kabupaten',
    value: 'cityName',
  },
  {
    label: 'Kecamatan',
    value: 'districtName',
  },
  {
    label: 'Kelurahan',
    value: 'villageName',
  },
  {
    label: 'Kode Pos',
    value: 'zipCode',
  },
  {
    label: 'Koordinat Lokasi',
    value: 'coordinate',
  },
];

export const merchantInformations2 = [
  { label: 'Telp/Fax', value: 'phoneNumber' },
  { label: 'Nomor NPWP', value: 'npwp' },
  { label: 'Jenis Usaha', value: 'businessTypeName' },
  { label: 'Jenis Bidang Usaha/Kegiatan', value: 'businessCategoryName' },
  { label: 'Lama Berdiri', value: 'since' },
];

export const ownerInformations = [
  { label: 'Nama Pemilik', value: 'name' },
  { label: 'Nomor Identitas Pemilik', value: 'idCardNumber' },
  { label: 'Alamat Pemilik Merchant', value: 'address' },
  { label: 'Provinsi', value: 'provinceName' },
  { label: 'Kota/Kabupaten', value: 'cityName' },
  { label: 'Kecamatan', value: 'districtName' },
  { label: 'Kelurahan', value: 'villageName' },
  { label: 'Email', value: 'email' },
  { label: 'Nomor Handphone', value: 'phoneNumber' },
  { label: 'Nama Pengurus', value: 'pic' },
  { label: 'Jabatan Pengurus', value: 'picRole' },
  { label: 'Nomor Handphone Pengurus', value: 'picPhoneNumber' },
];

export const bankInformations = [
  { label: 'Nama Bank', value: 'bankName' },
  { label: 'Nomor Rekening', value: 'accountNumber' },
  { label: 'Nama Pemilik Rekening', value: 'accountName' },
];
