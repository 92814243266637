import {
  setToken,
  setUserData,
  setExpireTime,
  clearStorages,
} from '../../utils/storage';
import { loginUser, logoutUser } from '../../utils/api';
import { FAILED, LOADING } from './constants';

export function login(data) {
  return (dispatch) => {
    dispatch(loadingAction(true));

    loginUser(data)
      .then((res) => {
        dispatch(loadingAction(false));
        if (res.data.accessToken) {
          const { accessToken, ...userData } = res.data;
          const { exp } = JSON.parse(atob(accessToken.split('.')[1]));
          setToken(accessToken);
          setExpireTime(exp);
          setUserData(userData);
          const redirect = sessionStorage.getItem('redirect');
          if(redirect){
            sessionStorage.removeItem('redirect');
            location.replace(redirect);
          }else{
            location.href = '/admin/first-login';
          }
        } else {
          dispatch(loginFailedAction('You are not allowed to access'));
        }
      })
      .catch((err) => {
        const message =
          err.code >= 400 && err.code < 500
            ? err.message
            : 'Username atau password yang Anda masukan salah';
        dispatch(loginFailedAction(message));
        dispatch(loadingAction(false));
      });
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    try {
      await logoutUser();
    } catch ({ message }) {
      dispatch(loginFailedAction(message));
    } finally {
      clearStorages();
      location.href = '/admin/login';
    }
  };
}

function loadingAction(isLoading) {
  return { type: LOADING, isLoading };
}

function loginFailedAction(message) {
  return { type: FAILED, message };
}
