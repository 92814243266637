import React from 'react';
import PropsTypes from 'prop-types';

const IconSettingMap = ({ className }) => {
  return(
    <svg className={className} fill="none" height="20" viewBox="0 0 12 20" width="12" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.75 0C10.9926 0 12 1.00736 12 2.25V17.75C12 18.9926 10.9926 20 9.75 20H2.25C1.00736 20 0 18.9926 0 17.75V2.25C0 1.00736 1.00736 0 2.25 0H9.75ZM7.25 16H4.75C4.33579 16 4 16.3358 4 16.75C4 17.1642 4.33579 17.5 4.75 17.5H7.25C7.66421 17.5 8 17.1642 8 16.75C8 16.3358 7.66421 16 7.25 16Z"/>
    </svg>

  );
};

export default IconSettingMap;

IconSettingMap.defaultProps = {
  className: ''
};

IconSettingMap.propTypes = {
  className: PropsTypes.string
};
