import React from 'react';
import PropTypes from 'prop-types';
export default function IcUpdateStock({ classname }){
  return(
    <svg className={classname} fill="none" height="27" viewBox="0 0 24 27" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0.5L14 8C14 9.38071 15.1193 10.5 16.5 10.5L24 10.5L24 23C24 24.3807 22.8807 25.5 21.5 25.5L12.988 25.5C14.389 24.0401 15.25 22.0581 15.25 19.875C15.25 15.3877 11.6123 11.75 7.125 11.75C6.01762 11.75 4.96199 11.9715 4 12.3727L4 3C4 1.61929 5.11929 0.5 6.5 0.5L14 0.5Z"/>
      <path d="M15.875 1.125L15.875 8C15.875 8.34518 16.1548 8.625 16.5 8.625L23.375 8.625L15.875 1.125Z"/>
      <path d="M14 19.875C14 23.672 10.922 26.75 7.125 26.75C3.32804 26.75 0.25 23.672 0.25 19.875C0.25 16.078 3.32804 13 7.125 13C10.922 13 14 16.078 14 19.875ZM3.375 19.25C3.02982 19.25 2.75 19.5298 2.75 19.875C2.75 20.2202 3.02982 20.5 3.375 20.5H9.36612L7.30806 22.5581C7.06398 22.8021 7.06398 23.1979 7.30806 23.4419C7.55214 23.686 7.94786 23.686 8.19194 23.4419L11.3169 20.3169C11.561 20.0729 11.561 19.6771 11.3169 19.4331L8.19194 16.3081C7.94787 16.064 7.55214 16.064 7.30806 16.3081C7.06398 16.5521 7.06398 16.9479 7.30806 17.1919L9.36612 19.25H3.375Z"/>
    </svg>
  );
}

IcUpdateStock.propTypes = {
  classname: PropTypes.string,
};

IcUpdateStock.defaultProps = {
  classname: '',
};
