import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';
export default function Banner({ className, icon, desc, type }) {
  const custStyle = [styles.root, styles[type], className].join(' ');

  const descRef = useRef(null);

  useEffect(() => {
    if (descRef.current) {
      descRef.current.innerHTML = desc;
    }
  }, [descRef.current, desc]);

  return (
    <div className={custStyle}>
      <span>{icon}</span>
      {typeof desc === 'string' ? <p ref={descRef} /> : <p>{desc}</p>}
    </div>
  );
}
Banner.defaultProps = {
  className: '',
  desc: '',
  icon: '',
  type: 'danger',
};
Banner.propTypes = {
  className: PropTypes.string,
  desc: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  type: PropTypes.oneOf(['danger', 'warning', 'success', 'info']),
};
