import { actionTypes } from './constants';

const state = {
  data: [],
  meta: {},
  loading: false,
  message: '',
  success: false,
};


export const initialState = {
  dataNotification: state,
  dataActiveNotification: state,
  detailNotification: state,
  orderActiveNotification: state,
  deleteNotification: {
    success: false,
    loading: false,
    data: {},
    message:''
  }
};

export default function reducer(state = initialState, action ) {
  const {
    type,
    data,
    loading,
    message = '',
    success = true,
    meta,
    key,
    form,
    error
  } = action;
  switch(type){
    case actionTypes.FAILED:
      return {
        ...state,
        [key]: {
          ...state[key],
          // data: initialState[key].data,
          error: error,
          loading: false,
          message,
          success: false,
        },
      };
    case actionTypes.LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          success: false,
          loading,
          message: '',
        },
      };
    case actionTypes.SUCCESS:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: data,
          meta,
          loading: false,
          error: {},
          message,
          success,
        },
      };
    case actionTypes.MESSAGE:
      return {
        ...state,
        [key]: {
          ...state[key],
          success: false,
          loading: false,
          message,
        },
      };
    case actionTypes.FORM:
      return {
        ...state,
        [key]: {
          ...state[key],
          form,
        },
      };
    default:
      return state;
  }
}
