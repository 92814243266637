import React from 'react';
import { Menu, Button } from '@mantine/core';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';

export default function MenuDropdown({
  listDropDown, children, className, position, offset, closeOnClickOutside, closeOnEscape,
  width, zIndex
}){
  const cusStyle = [styles.container, className].join(' ');

  return(
    <Menu closeOnClickOutside={closeOnClickOutside} closeOnEscape={closeOnEscape}
      offset={offset} position={position}   width={width} zIndex={zIndex} >
      <div className={cusStyle}>
        <Menu.Target>
          <Button className={styles.toggleMenu} >{children}</Button>
        </Menu.Target>
        <Menu.Dropdown className={styles.dropDownContainer}>
          {listDropDown.map((list) => {
            return(
              <Menu.Item
                icon={list.icon !== '' ? list.icon : null}
                key={list.name}
                // eslint-disable-next-line react/jsx-handler-names
                onClick={list.onClick}
              >{list.name}</Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </div>
    </Menu>
  );
}

MenuDropdown.defaultProps = {
  children: [],
  className: '',
  closeOnClickOutside: true,
  closeOnEscape: true,
  listDropDown: [],
  offset: 0,
  position: 'bottom-start',
  width: 200,
  zIndex: 10,
};

MenuDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  listDropDown: PropTypes.array,
  offset: PropTypes.number,
  position: PropTypes.string,
  width: PropTypes.number,
  zIndex: PropTypes.number,
};
