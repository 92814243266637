import { FAILED, LOADING, SUCCESS } from './constants';

export const initialState = {
  dataSummary: {},
  dataSummaryDetails: [],
  dataChartReport: [],
  dataProducts: [],
  dataBestSelling: [],
  isLoadingSummary: false,
  isLoadingSummaryDetails: false,
  isLoadingChartReport: false,
  isLoadingProducts: false,
  isLoadingBestSelling: false,
  messageSummary: '',
  messageSummaryDetails: '',
  messageDataChartReport: '',
  messageProducts: '',
  messageBestSelling: '',
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`message${key}`]: message,
        [`isLoading${key}`]: false,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
      };
    default:
      return state;
  }
}
