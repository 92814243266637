import React from 'react';
import PropTypes from 'prop-types';
export default function IconInfoRevition({ className }){
  return(
    <svg className={className} fill="none" height="48" viewBox="0 0 49 48" width="49" xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d="M25 44C36.0457 44 45 35.0457 45 24C45 12.9543 36.0457 4 25 4C13.9543 4 5 12.9543 5 24C5 35.0457 13.9543 44 25 44Z" fill="#2172CC" fillRule="evenodd"/>
      <path d="M25 19V25M25 29.01L25.01 28.999M25 34C30.523 34 35 29.523 35 24C35 18.477 30.523 14 25 14C19.477 14 15 18.477 15 24C15 29.523 19.477 34 25 34Z" stroke="#E0F3FE" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>


  );
}
IconInfoRevition.defaultProps = {
  className: ''
};
IconInfoRevition.propTypes = {
  className: PropTypes.string
};
