import React, { forwardRef } from 'react';
import styles from './styles.scoped.css';

export const itemComponent = forwardRef((data, ref) => {
  return(
    <div ref={ref} {...data}>
      <div className={styles.itemComponent}>
        <span>{data.label}</span>
      </div>
    </div>
  );
});
itemComponent.displayName = 'itemComponent';
