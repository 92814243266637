export const FAILED = 'Stock/failed';
export const LOADING = 'Stock/loading';
export const SUCCESS = 'Stock/success';

export const categories = [
  {
    label: 'LPG',
    value: 0,
    icon: 'assets/ic-lpg.svg',
    unit: 'Tabung',
    key: 'lpg',
  },
  {
    label: 'BBM',
    value: 0,
    icon: 'assets/ic-bbm.svg',
    unit: 'Liter',
    key: 'bbm',
  },
  {
    label: 'Pelumas',
    value: 0,
    icon: 'assets/ic-pelumas.svg',
    unit: 'Botol',
    key: 'pelumas',
  },
  {
    label: 'Refrigeran',
    value: 0,
    icon: 'assets/ic-refrigeran.svg',
    unit: 'Kg',
    key: 'refrigeran',
  },
];
