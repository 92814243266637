/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconManagementMerchant({ className }){
  return(
    <svg className={className} fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5538 16.229L15.7512 11.4263C14.8038 10.4789 13.3888 10.2943 12.2486 10.8562L7.87661 6.48421V3.93728L2.6269 0L0.00205064 2.62485L3.93933 7.87456H6.48626L10.8583 12.2466C10.3005 13.3868 10.481 14.8017 11.4284 15.7491L16.231 20.5518C16.8298 21.1506 17.7977 21.1506 18.3924 20.5518L20.5538 18.3904C21.1485 17.7916 21.1485 16.8237 20.5538 16.229ZM13.6062 9.228C14.7669 9.228 15.8578 9.67915 16.6781 10.4994L17.4737 11.2951C18.1217 11.0121 18.7369 10.6184 19.2701 10.0852C20.7917 8.56359 21.3085 6.42269 20.8245 4.47866C20.7343 4.10954 20.2708 3.9824 20.0002 4.25308L16.9488 7.30448L14.164 6.84103L13.7005 4.05622L16.7519 1.00483C17.0226 0.734139 16.8913 0.270688 16.5181 0.176357C14.5741 -0.303499 12.4332 0.213269 10.9157 1.73076C9.74682 2.89964 9.19724 4.44174 9.22595 5.97975L12.5931 9.34694C12.9254 9.26902 13.2699 9.228 13.6062 9.228ZM9.34489 12.5911L7.01943 10.2656L0.769 16.5202C-0.256333 17.5455 -0.256333 19.2065 0.769 20.2319C1.79433 21.2572 3.45537 21.2572 4.48071 20.2319L9.54996 15.1626C9.23826 14.3465 9.14392 13.4565 9.34489 12.5911ZM2.6269 19.3583C2.08553 19.3583 1.64258 18.9154 1.64258 18.374C1.64258 17.8285 2.08143 17.3897 2.6269 17.3897C3.17238 17.3897 3.61122 17.8285 3.61122 18.374C3.61122 18.9154 3.17238 19.3583 2.6269 19.3583Z" fill="#25282B"/>
    </svg>

  );
}
IconManagementMerchant.defaultProps = {
  className: ''
};
IconManagementMerchant.propTypes = {
  className: PropTypes.string
};
