import { FAILED, LOADING, SUCCESS } from './constants';

const initialState = {
  dataMerchantTypes: [],
  isLoadingdDataMerchantTypes: false,
  messageDataMerchantTypes: '',
  dataProductCategories: [],
  isLoadingProductCategories: false,
  messageProductCategories: '',
  dataDetailUser: { permitions : [] },
  isLoadingDetailUser: false,
  dataSbm: [],
  isLoadingSbm: false,
  messageSbm: '',
  dataAgents: [],
  isLoadingAgents: false,
  messageAgents: '',
  dataRegionals: [],
  isLoadingRegionals: false,
  messageRegionals: '',
  dataProvinces: [],
  isLoadingProvinces: false,
  messageProvinces: '',
  dataCities: [],
  isLoadingCities: false,
  messageCities: '',
  dataDistricts: [],
  isLoadingDistricts: false,
  messageDistricts: '',
  dataVillages: [],
  isLoadingVillages: false,
  messageVillages: '',
  dataRoles: [],
  isLoadingRoles: false,
  messageRoles: '',
  dataBanks: [],
  isLoadingBanks: false,
  messageBanks: '',
  dataSam: [],
  isLoadingSam: false,
  messageSam: '',
};

const pageBase = (state = initialState, action) => {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`message${key}`]: message,
        [`isLoading${key}`]: false,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
      };
    default:
      return state;
  }
};

export default pageBase;
