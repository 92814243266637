import React from 'react';
import PropTypes from 'prop-types';
export default function IconLogSyns({ className }) {
  return(
    <svg className={className} fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.49462 19.999L8.49538 12.501L5.50038 12.501L5.49962 19.999H8.49462Z"/>
      <path clipRule="evenodd" d="M12.3355 11.001H11.7537C11.3395 11.001 11.0037 11.3368 11.0037 11.751V12.3317C10.3714 13.2274 10 14.3203 10 15.5C10 17.3597 10.923 19.0039 12.3359 19.9993L9.99562 19.999L9.99633 11.751C9.99633 11.3368 9.66054 11.001 9.24633 11.001L4.75038 11.001C4.33617 11.001 4.00038 11.3368 4.00038 11.751L3.99962 19.999L1.75162 20C1.37193 20 1.05813 19.7178 1.00847 19.3518L1.00162 19.25L1.00084 8.74776C0.421331 8.112 0.0529275 7.28045 0.00647069 6.36396L0.00148392 6.16674L0.00128651 4.29097C-0.00678396 4.15366 0.0228694 4.01238 0.0972192 3.88313L0.147 3.807L0.201656 3.74193L3.45055 0.239913C3.56882 0.112432 3.72689 0.030813 3.89705 0.00714779L4.00038 0L16.0004 0C16.1743 0 16.3416 0.0603719 16.4747 0.169024L16.5502 0.239913L19.8225 3.76824L19.8531 3.80714C19.9549 3.94281 20.0008 4.09665 20.0008 4.24775L19.9993 6.16674C19.9993 7.16092 19.6207 8.06668 18.9999 8.74776L18.9997 11.2569C18.0488 10.4717 16.8295 10 15.5 10C14.322 10 13.2304 10.3704 12.3355 11.001ZM1.50138 5.002L6.16638 5.002L6.16663 6.16674L6.16125 6.32644C6.07918 7.54019 5.06862 8.49931 3.83406 8.49931L3.67435 8.49393L3.51754 8.47801C2.37901 8.32356 1.50148 7.34763 1.50148 6.16674L1.50138 5.002ZM7.66638 5.002L12.3314 5.002L12.3318 6.16674L12.3264 6.32644C12.2443 7.54019 11.2338 8.49931 9.9992 8.49931L9.8395 8.49393L9.68268 8.47801C8.54415 8.32356 7.66663 7.34763 7.66663 6.16674L7.66638 5.002ZM13.8334 5.002L18.4984 5.002L18.4993 6.16674L18.4939 6.32644C18.4118 7.54019 17.4013 8.49931 16.1667 8.49931L16.007 8.49393L15.8502 8.47801C14.7117 8.32356 13.8341 7.34763 13.8341 6.16674L13.8334 5.002ZM4.32638 1.5L7.061 1.5L6.446 3.502L2.46938 3.502L4.32638 1.5ZM8.631 1.5L11.369 1.5L11.985 3.502L8.015 3.502L8.631 1.5ZM12.939 1.5L15.6734 1.5L17.5304 3.502L13.554 3.502L12.939 1.5Z" fillRule="evenodd"/>
      <path d="M11 15.5C11 17.9853 13.0147 20 15.5 20C17.9853 20 20 17.9853 20 15.5C20 13.0147 17.9853 11 15.5 11C13.0147 11 11 13.0147 11 15.5ZM17.5 12.5C17.7761 12.5 18 12.7239 18 13V14.5C18 14.7762 17.7761 15 17.5 15H16C15.7239 15 15.5 14.7762 15.5 14.5C15.5 14.2239 15.7239 14 16 14H16.4682C16.4179 13.9722 16.3663 13.9465 16.3135 13.923C16.0682 13.8137 15.8034 13.755 15.535 13.7503C15.2665 13.7456 14.9998 13.795 14.7508 13.8956C14.5018 13.9962 14.2756 14.1459 14.0857 14.3358C13.8905 14.5311 13.5739 14.5311 13.3786 14.3358C13.1834 14.1405 13.1834 13.824 13.3786 13.6287C13.6635 13.3439 14.0027 13.1193 14.3762 12.9684C14.7497 12.8176 15.1497 12.7434 15.5524 12.7505C15.9552 12.7575 16.3524 12.8456 16.7203 13.0095C16.8162 13.0521 16.9095 13.0997 17 13.152V13C17 12.7239 17.2239 12.5 17.5 12.5ZM16.6238 18.0316C16.2503 18.1825 15.8503 18.2566 15.4476 18.2496C15.0448 18.2425 14.6476 18.1544 14.2797 17.9906C14.1838 17.9479 14.0905 17.9003 14 17.8481V18C14 18.2762 13.7761 18.5 13.5 18.5C13.2239 18.5 13 18.2762 13 18V16.5C13 16.2239 13.2239 16 13.5 16L15 16C15.2761 16 15.5 16.2239 15.5 16.5C15.5 16.7762 15.2761 17 15 17H14.5318C14.5821 17.0279 14.6337 17.0536 14.6865 17.0771C14.9318 17.1863 15.1966 17.245 15.465 17.2497C15.7335 17.2544 16.0002 17.205 16.2492 17.1044C16.4982 17.0038 16.7244 16.8541 16.9143 16.6642C17.1095 16.469 17.4261 16.469 17.6214 16.6642C17.8166 16.8595 17.8166 17.1761 17.6214 17.3713C17.3365 17.6562 16.9973 17.8807 16.6238 18.0316Z"/>
    </svg>

  );
}

IconLogSyns.defaultProps = {
  className: ''
};

IconLogSyns.propTypes = {
  className: PropTypes.string
};
