import React from 'react';
import IconFailed from '../../components/icons/IconFailed';
import IconInfoRevition from '../../components/icons/IconInfoRevition';
import IconSuccess from '../../components/icons/IconSuccess';
import styles from './styles.scoped.css';

export const FAILED = 'verification-merchant/failed';
export const LOADING = 'verification-merchant/loading';
export const SUCCESS = 'verification-merchant/success';

export const MERCHANT_VERIFICATION_FAILED =
  'merchant/verification/failed';
export const MERCHANT_VERIFICATION_LOADING =
  'merchant/verification/loading';
export const MERCHANT_VERIFICATION_SUCCESS =
  'merchant/verification/success';
export const MERCHANT_VERIFICATION_SET_DATA =
  'merchant/verification/set_data';
export const MERCHANT_VERIFICATION_SET_FILTER =
  'merchant/verification/set_filter';
export const IS_OVER_LIMIT_ROWS_SET_DATA =
  'merchant/verification/set_selected_rows';

export const label = {
  'Ditolak' : 'Telah menolak',
  'Disetujui' : 'Telah menyetujui',
  'Perlu Perbaikan' : 'Perlu perbaikan data',
};

export const icon = {
  'Disetujui' : <IconSuccess className={styles.icon} />,
  'Perlu Perbaikan' : <IconInfoRevition className={styles.icon} />,
  'Ditolak' : <IconFailed className={styles.icon} />,
};

export const type = {
  'Disetujui' : 'success',
  'Perlu Perbaikan' : 'info',
  'Ditolak' : 'danger',
};
