/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconAturHET({ className }){
  return(
    <svg className={className} fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 1.71875C12.125 1.4375 11.625 1.5625 11.4062 1.9375L10.9062 2.8125C10.7188 3.15625 10.8125 3.5625 11.0938 3.78125C12.4688 4.8125 13.2188 6.375 13.25 7.96875C13.25 10.9375 10.8438 13.25 8 13.25C5.0625 13.25 2.71875 10.9062 2.75 8C2.75 6.28125 3.5625 4.75 4.875 3.78125C5.15625 3.5625 5.25 3.15625 5.0625 2.8125L4.5625 1.9375C4.34375 1.5625 3.84375 1.4375 3.46875 1.71875C1.5 3.125 0.21875 5.4375 0.25 8.03125C0.25 12.2812 3.75 15.7812 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 5.40625 14.4688 3.125 12.5 1.71875ZM9.25 8.25V0.75C9.25 0.34375 8.90625 0 8.5 0H7.5C7.0625 0 6.75 0.34375 6.75 0.75V8.25C6.75 8.6875 7.0625 9 7.5 9H8.5C8.90625 9 9.25 8.6875 9.25 8.25Z" fill="#EA2036"/>
    </svg>
  );
}
IconAturHET.defaultProps = {
  className: ''
};
IconAturHET.propTypes = {
  className: PropTypes.string
};

