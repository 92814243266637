import { FAILED, LOADING, SET_DATA, SUCCESS } from './constants';

export const initialState = {
  dataTransactions: {
    data: [],
    meta: {
      scrollId: '',
      page: 0,
      size: 100,
      totalPage: 1,
      totalData: 0,
      loadedAt: new Date(),
      lastDataAt: new Date(),
    },
  },
  isLoadingTransactions: false,
  messageTransactions: '',
  dataDownloadTransaction: {
    excelLink: '',
    pdfLink: '',
  },
  isLoadingDownloadTransaction: false,
  messageDownloadTransaction: '',
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    case SET_DATA:
      return {
        ...state,
        [`data${key}`]: {
          data: action.scrollId
            ? [...state.dataTransactions.data, ...data.data]
            : data.data,
          meta: {
            ...data.meta,
            loadedAt: action.scrollId
              ? state.dataTransactions.meta.loadedAt
              : new Date(),
            page: action.scrollId ? state.dataTransactions.meta.page + 1 : 1,
          },
        },
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    default:
      return state;
  }
}
