import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';
import { analytics } from '../../../utils/firebase';
import classNames from '../../../utils/classNames';

Button.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  eventName: 'button_click',
  eventParams: {},
  onClick: () => {},
  outlined: false,
  style: 'default',
  title: '',
  type: 'submit',
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  eventName: PropTypes.string,
  eventParams: PropTypes.object,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  style: PropTypes.oneOf(['default', 'danger', 'success', 'blue', 'outlined','transparent']),
  title: PropTypes.string,
  type: PropTypes.string,
};

function Button(props) {
  const {
    children,
    className,
    disabled,
    eventName,
    eventParams,
    onClick,
    outlined,
    style,
    type,
    title
  } = props;

  const classes = classNames(
    styles.root,
    outlined ? styles.outlined : styles[style],
    className
  );

  const onBtnClick = () => {
    analytics.logEvent(eventName, eventParams);
    onClick();
  };

  return (
    <button
      className={classes}
      disabled={disabled}
      onClick={onBtnClick}
      title={title}
      type={type}
    >
      {children}
    </button>
  );
}

export default Button;
