import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import React from 'react';
import { handleValueSelected } from '../../../utils/builder/common';
import MultipleSelect from '../../fields/MultipleSelect/MultipleSelect';
import Select from '../../fields/SelectBox/Select';
import IconUpDownArrow from '../../icons/IconUpDownArrow';
import DateRangePicker from '../../elements/DateRangePicker/DateRangePicker';

export const defaultDateRange = [
  dayjs().subtract(6, 'day').toDate(),
  dayjs().toDate(),
];

export const initialValues = {
  submissionDate: defaultDateRange,
  statusDate: dayjs().toDate(),
  regional: '',
  provinceId: '',
  cityId: '',
  districtId: '',
  villageId: '',
  status: '',
  agenId: ''
};

export const Fields = ({
  values = initialValues,
  handleChangeValues,
  handleChangeValuesDate,
  handleGetAgents,
  dropdownData,
  regionalId,
  // provinceId,
  // cities,
  // cityId,
  // minDate,
  // maxDate,
  sam, sbm,
  handleSearchFilter,
  valueDate,
  setValueDate
}) => {


  const datePickerProps = {
    dropdownPosition: 'bottom-start',
    allowSingleDateInRange: true,
    valueFormat: 'DD MMM YYYY',
    locale: 'id',
    placeholder: 'Pilih tanggal',
    rightSection: <IconUpDownArrow />,
  };

  return [
    {
      label: 'Tanggal Pengajuan',
      component: (
        <DateRangePicker
          inputProps={datePickerProps}
          maxDate={new Date()}
          onChange={(value) => {
            setValueDate(value);
            handleChangeValuesDate(value);
            if (Object.values(value).every((item) => Boolean(item))) {
              handleChangeValues({
                name: 'submissionDate',
                value,
              });
            }
          }}
          type="range"
          value={valueDate}
        />
      ),
    },
    {
      label: 'Tanggal Status',
      component: (
        <DatePickerInput
          {...datePickerProps}
          maxDate={new Date()}
          onChange={(value) => {
            handleChangeValues({
              name: 'statusDate',
              value,
            });
          }}
          value={values.statusDate}
        />
      ),
    },
    {
      label: 'Wave',
      component: (
        <Select
          data={dropdownData.waves}
          eventName="wave"
          hideLabel
          name="wave"
          onChangeSelect={(value) =>
            handleChangeValues({ name: 'wave', value })
          }
          placeholder="Semua Wave"
          rightSection={<IconUpDownArrow />}
          valueForm={values.wave}
        />
      ),
    },
    {
      label: 'Regional',
      disabled: regionalId ? true : false,
      component: (
        <MultipleSelect
          border={false}
          data={dropdownData.regional}
          disabled={regionalId ? true : false}
          eventName="regional"
          hideLabel
          name="regional"
          onChange={(value) => {
            const isSelectedAll = value.findIndex((val) => val === '*');
            handleChangeValues({ name: 'regional', value: isSelectedAll > -1 || value.length === 0 ? '*' : value });
          }
          }
          overflow={'unset'}
          placeholder="Semua Regional"
          rightSection={<IconUpDownArrow />}
          searchable={true}
          value={handleValueSelected(values.regional, regionalId)}        />
      ),
    },
    {
      label: 'SAM',
      disabled: sam?.id ? true : false,
      component: (
        <MultipleSelect
          border={false}
          data={dropdownData.sam}
          disabled={sam?.id ? true : false}
          eventName="sam"
          hideLabel
          name="sam"
          onChange={(value) => {
            const isSelectedAll = value.findIndex((val) => val === '*');
            handleChangeValues({ name: 'sam', value: isSelectedAll > -1 || value.length === 0 ? '*' : value });
          }
          }
          overflow={'unset'}
          placeholder="Semua SAM"
          rightSection={<IconUpDownArrow />}
          searchable={true}
          value={handleValueSelected(values.samId, sam?.id)}
        />
      ),
    },
    {
      label: 'SBM',
      disabled: sbm?.id ? true : false,
      component: (
        <MultipleSelect
          border={false}
          data={dropdownData.sbm}
          debounce={true}
          disabled={sbm?.id ? true : false}
          eventName="sbm"
          hideLabel
          name="sbm"
          onChange={(value) => {
            const isSelectedAll = value.findIndex((val) => val === '*');
            handleChangeValues({ name: 'sbm', value: isSelectedAll > -1 || value.length === 0 ? '*' : value });
          }
          }
          onSearchChange={handleSearchFilter}
          overflow={'unset'}
          placeholder="Semua SBM"
          rightSection={<IconUpDownArrow />}
          searchable={true}
          value={handleValueSelected(values.sbmId, sbm?.id)}
        />
      ),
    },
    {
      label: 'Provinsi',
      // disabled: provinceId !== '',
      component: (
        <MultipleSelect
          border={false}
          data={dropdownData.provinces}
          eventName="provinces"
          hideLabel
          name="provinceId"
          onChange={(value) =>{
            const isSelectedAll = value.findIndex((val) => val === '*');
            handleChangeValues({ name: 'provinceId', value: isSelectedAll > -1 || value.length === 0 ? '*' : value });
          }
          }
          overflow={'unset'}
          placeholder="Semua Provinsi"
          rightSection={<IconUpDownArrow />}
          searchable={true}
          value={values.provinceId}
        />
      ),
    },
    {
      label: 'Kota/Kab',
      // disabled: JSON.stringify(cities)?.length === 40,
      component: (
        <MultipleSelect
          border={false}
          data={dropdownData.cities}
          debounce={true}
          eventName="cities"
          hideLabel
          name="cityId"
          onChange={(value) => {
            const isSelectedAll = value.findIndex((val) => val === '*');
            handleChangeValues({ name: 'cityId', value: isSelectedAll > -1 || value.length === 0 ? '*' : value });
          }
          }
          onSearchChange={handleSearchFilter}
          overflow={'unset'}
          placeholder="Semua Kota/Kab"
          rightSection={<IconUpDownArrow />}
          searchable={true}
          value={values.cityId}
        />
      ),
    },
    {
      label: 'Kecamatan',
      // disabled: values.cityId?.length === 2 || values.cityId?.length === 0,
      component: (
        <MultipleSelect
          border={false}
          data={dropdownData.districts}
          debounce={true}
          eventName="districts"
          hideLabel
          name="districtId"
          onChange={(value) => {
            const isSelectedAll = value.findIndex((val) => val === '*');
            handleChangeValues({ name: 'districtId', value: isSelectedAll > -1 || value.length === 0 ? '*' : value });
          }
          }
          onSearchChange={handleSearchFilter}
          overflow={'unset'}
          placeholder="Semua Kecamatan"
          rightSection={<IconUpDownArrow />}
          searchable={true}
          value={values.districtId}
        />
      ),
    },
    {
      label: 'Kelurahan' ,
      // disabled: values.districtId === undefined,
      component: (
        <MultipleSelect
          border={false}
          data={dropdownData.villages}
          debounce={true}
          eventName="villages"
          hideLabel
          name="villageId"
          onChange={(value) => {
            const isSelectedAll = value.findIndex((val) => val === '');
            handleChangeValues({ name: 'villageId', value: isSelectedAll > -1 || value.length === 0 ? '' : value });
          }
          }
          onSearchChange={handleSearchFilter}
          overflow={'unset'}
          placeholder="Semua Kelurahan"
          rightSection={<IconUpDownArrow />}
          searchable={true}
          value={values.villageId}
        />
      ),
    },
    {
      label: 'ID Agen',
      component: (
        <Select
          data={dropdownData.agents}
          eventName="agent"
          hideLabel
          name="agent"
          onChangeSelect={(value) =>
            handleChangeValues({ name: 'agenId', value })
          }
          onSearchChange={handleGetAgents}
          placeholder="Pilih Agen"
          rightSection={<IconUpDownArrow />}
          valueForm={values.agenId}
        />
      ),
    },
  ];
};
