/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconArrowDown({ className }){
  return(
    <svg className={className} height="9" viewBox="0 0 14 9" width="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.48188 8.28781L0.548013 2.42106C0.261828 2.13811 0.261828 1.67938 0.548013 1.39646L1.2401 0.712204C1.5258 0.429739 1.98883 0.429196 2.2752 0.710996L7.00006 5.3605L11.7249 0.710996C12.0112 0.429196 12.4743 0.429739 12.76 0.712204L13.4521 1.39646C13.7383 1.67941 13.7383 2.13814 13.4521 2.42106L7.51823 8.28781C7.23205 8.57073 6.76807 8.57073 6.48188 8.28781Z" fill="#25282B"/>
    </svg>
  );
}
IconArrowDown.defaultProps = {
  className: ''
};
IconArrowDown.propTypes = {
  className: PropTypes.string
};
