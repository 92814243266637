/* eslint-disable no-nested-ternary */
import { Badge } from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';
import IconActive from '../../icons/IconActive';
import IconNonActive from '../../icons/IconNonActive';
export default function BadgeStatus({ label }) {
  const color = {
    'Ditolak': 'pink',
    'Disetujui': 'green',
    'Sedang Diproses': 'indigo',
    'Perlu Perbaikan': 'grape',
    'Perlu Diproses': 'orange',
    'primary': 'indigo',
    'Lengkap': 'green',
    'Gagal': 'red',
    'Aktif': 'green',
    'Tidak Aktif': 'red',
    'permanen': 'green',
    'sementara': 'blue',
    'registrasi': 'orange',
    'Gagal Daftar': 'red',
    'Gagal Perbarui Data': 'red',
    'Stok Tersinkronisasi': 'green',
    'Stok Sedang Disinkron': 'orange',
    'Berhasil Upload': 'blue',
  };

  const icon = {
    'Aktif' : <IconActive />,
    'Tidak Aktif' : <IconNonActive />,
  };

  return (
    <Badge color={color[label] || 'gray'} style={{ textTransform: 'capitalize', fontWeight: '500' }}>
      {icon[label]} {label}
    </Badge>
  );
}

BadgeStatus.defaultProps = {
  label: '',
};

BadgeStatus.propTypes = {
  label: PropTypes.string,
};
