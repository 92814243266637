import { FAILED, LOADING, SUCCESS } from './constants';

const initialSummary = {
  totalStock: 0,
  detail: [],
};

export const initialDataStock = {
  lpg: { ...initialSummary },
  bbm: { ...initialSummary },
  pelumas: { ...initialSummary },
  refrigeran: { ...initialSummary },
};

export const initialState = {
  dataStock: { ...initialDataStock },
  isLoadingStock: false,
  messageStock: '',
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`message${key}`]: message,
        [`isLoading${key}`]: false,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
      };
    default:
      return state;
  }
}
