import React from 'react';
import PropTypes from 'prop-types';
export default function IconAudit({ className }){
  return(
    <svg className={className} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 19L21 5C21 3.9 20.1 3 19 3L5 3C3.9 3 3 3.9 3 5L3 19C3 20.1 3.9 21 5 21L19 21C20.1 21 21 20.1 21 19ZM7 8C7 7.45 7.45 7 8 7L11 7C11.55 7 12 7.45 12 8C12 8.55 11.55 9 11 9L8 9C7.45 9 7 8.55 7 8ZM7 12C7 11.45 7.45 11 8 11L9 11C9.55 11 10 11.45 10 12C10 12.55 9.55 13 9 13L8 13C7.45 13 7 12.55 7 12ZM12 12C12 11.45 12.45 11 13 11C13.55 11 14 11.45 14 12C14 12.55 13.55 13 13 13C12.45 13 12 12.55 12 12ZM7 16C7 15.45 7.45 15 8 15L16 15C16.55 15 17 15.45 17 16C17 16.55 16.55 17 16 17L8 17C7.45 17 7 16.55 7 16Z" fillRule="evenodd"/>
    </svg>
  );
}
IconAudit.defaultProps = {
  className: ''
};
IconAudit.propTypes = {
  className: PropTypes.string
};
