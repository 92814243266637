import { FAILED, LOADING, SUCCESS, SET_DATA } from './constants';

export const initialDownloadState = {
  generating: false,
  link: null,
};

export const initialState = {
  dataManualLoggingActivation: {
    data: [],
    meta: {
      scrollId: '',
      page: 0,
      totalPage: 0,
      totalData: 0,
      loadedAt: new Date(),
      lastDataAt: new Date(),
    },
  },
  dataSettingManualActivation: {
    data: [],
    meta: {
      page: 0,
      totalPage: 0,
      totalData: 0,
      size: 0
    },
  },
  dataFilter: {},
  isLoadingManualLoggingActivation: false,
  isLoadingSettingManualActivation: false,
  messageManualLoggingActivation: '',
  messageSettingManualActivation: ''
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    case SET_DATA:
      return {
        ...state,
        [`data${key}`]: {
          data: action.scrollId
            ? [...state.dataManualLoggingActivation.data, ...data.data]
            : data.data,
          meta: {
            ...data.meta,
            loadedAt: action.scrollId
              ? state.dataManualLoggingActivation.meta.loadedAt
              : new Date(),
            page: action.scrollId
              ? state.dataManualLoggingActivation.meta.page + 1
              : 1,
          },
        },
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    default:
      return state;
  }
}
