import PropTypes from 'prop-types';

export const propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  eventName: PropTypes.string,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  onChangeSelect: PropTypes.func,
  onClearFields: PropTypes.func,
  onSearchChange: PropTypes.func,
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  meta: PropTypes.object,
  valueForm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showErrorWithoutTouched: PropTypes.bool,
};

export const defaultProps = {
  className: '',
  data: [],
  eventName: 'select_change',
  input: {},
  inputProps: {},
  onChangeSelect: () => {},
  onClearFields: () => {},
  onSearchChange: () => {},
  required: false,
  searchable: true,
  meta: {},
  valueForm: '',
  showErrorWithoutTouched: false,
};
