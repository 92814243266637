import React from 'react';
import PropTypes from 'prop-types';
export default function IconTerm({ className }){
  return(
    <svg className={className} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3096_57140)">
        <path d="M12 9L16 9C16.55 9 17 8.55 17 8C17 7.45 16.55 7 16 7L12 7C11.45 7 11 7.45 11 8C11 8.55 11.45 9 12 9ZM12 13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H12C11.45 11 11 11.45 11 12C11 12.55 11.45 13 12 13ZM12 17H16C16.55 17 17 16.55 17 16C17 15.45 16.55 15 16 15H12C11.45 15 11 15.45 11 16C11 16.55 11.45 17 12 17ZM7 7H9V9H7L7 7ZM7 11H9V13H7L7 11ZM7 15H9V17H7V15ZM20 3L4 3C3.45 3 3 3.45 3 4L3 20C3 20.55 3.45 21 4 21L20 21C20.55 21 21 20.55 21 20L21 4C21 3.45 20.55 3 20 3ZM19 19L5 19L5 5L19 5V19Z"/>
      </g>
      <defs>
        <clipPath id="clip0_3096_57140">
          <rect fill="white" height="24" width="24"/>
        </clipPath>
      </defs>
    </svg>


  );
}
IconTerm.defaultProps = {
  className: ''
};
IconTerm.propTypes = {
  className: PropTypes.string
};
