import React from 'react';
import styles from './styles.scoped.css';
import { Select as SelectMantine } from '@mantine/core';
import { analytics } from '../../../utils/firebase';
import { defaultProps, propTypes } from './constants';
function Select(props) {
  const {
    className,
    data,
    eventName,
    input,
    inputProps,
    onChangeSelect,
    onClearFields,
    onBlur,
    searchable,
    valueForm,
    onSearchChange,
    required,
    showErrorWithoutTouched,
    meta: { touched, error },
    ...restProps
  } = props;

  const classes = [styles.root, className].filter(Boolean).join(' ');
  const value = input.value || valueForm;

  const onSelectChange = (event) => {
    analytics.logEvent(eventName, {
      value: event,
    });

    onChangeSelect(event);
    input.onChange && input.onChange(event);
    onClearFields && onClearFields();
  };
  const onSelectBlur = (event) => {
    onBlur && onBlur(event);
    input.onBlur && input.onBlur();
  };

  return (
    <div>
      <SelectMantine
        {...restProps}
        {...inputProps}
        className={classes}
        data={data}
        error={Boolean(touched && error)}
        onBlur={onSelectBlur}
        onChange={onSelectChange}
        onKeyUp={onSearchChange}
        searchable={searchable}
        styles={{ label: { fontWeight: '600', fontSize: '1rem' } }}
        value={value}
        withAsterisk={required}
      />
      {inputProps?.info && <span>{inputProps?.info}</span>}
      {touched && error && <span className={styles.error}>{error}</span>}
      {error && showErrorWithoutTouched && (
        <span className={styles.error}>{error}</span>
      )}
    </div>
  );
}

Select.defaultProps = {
  ...defaultProps,
};

Select.propTypes = { ...propTypes };

export default Select;
