export const actionTypes = {
  FAILED: 'myptm-activation/failed',
  LOADING: 'myptm-activation/loading',
  SUCCESS: 'myptm-activation/success',
  FILTER: 'myptm-activation/filter',
  MESSAGE: 'myptm-activation/message',
};

export const dynamicContent = {
  PENDING: {
    info: 'Silahkan pilih pangkalan LPG 3 kg yang akan di aktivasi lalu klik unduh data untuk mengunduh data pangkalan LPG 3 kg.',
    button: 'Unduh 0 Data',
    color: 'orange',
  },
  'ON_PROCESS': {
    info: 'Silahkan pilih pangkalan LPG 3 kg untuk proses aktivasi.',
    button: 'Proses',
    color: 'blue',
  },
  ACTIVE: {
    color: 'green',
  },
};

export const status = {
  aktif: 'ACTIVE',
  'perlu aktivasi': 'PENDING',
  'sedang diproses': 'ON_PROCESS'
};
