import React from 'react';
import PropTypes from 'prop-types';
export default function LogoMap({ className }){
  return(
    <svg className={className} fill="none" height="40" viewBox="0 0 180 40" width="180" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_914_3)">
        <path d="M173.116 0.103027H6.88428C3.0822 0.103027 0 3.20896 0 7.04032V33.0337C0 36.8651 3.0822 39.971 6.88428 39.971H173.116C176.918 39.971 180 36.8651 180 33.0337V7.04032C180 3.20896 176.918 0.103027 173.116 0.103027Z" fill="url(#paint0_linear_914_3)"/>
        <path d="M173.116 1.68713H6.88425C3.95039 1.68713 1.57202 4.08381 1.57202 7.04027V33.0337C1.57202 35.9901 3.95039 38.3868 6.88425 38.3868H173.116C176.05 38.3868 178.428 35.9901 178.428 33.0337V7.04027C178.428 4.08381 176.05 1.68713 173.116 1.68713Z" fill="white"/>
        <path d="M173.411 1.55518H31.3101V20.9611H178.559V6.74329C178.559 5.36743 178.016 4.04801 177.051 3.07513C176.085 2.10224 174.776 1.55553 173.411 1.55518Z" fill="url(#paint1_linear_914_3)"/>
        <path d="M32.7709 0.134766H6.79794C3.06242 0.134766 0.0341797 3.18632 0.0341797 6.95061V33.1235C0.0341797 36.8878 3.06242 39.9394 6.79794 39.9394H32.7709C36.5064 39.9394 39.5346 36.8878 39.5346 33.1235V6.95061C39.5346 3.18632 36.5064 0.134766 32.7709 0.134766Z" fill="url(#paint2_linear_914_3)"/>
        <path d="M7.6769 12.8661C8.38432 12.8661 9.03934 13.2054 9.43236 13.4707L8.87166 14.5757C8.87166 14.5757 8.29786 14.1268 7.76598 14.1268C7.41489 14.1268 7.21314 14.3248 7.21314 14.6205C7.21314 14.9163 7.55113 15.0258 7.83803 15.1354C8.01096 15.2027 8.29 15.3202 8.4341 15.3783C9.25943 15.7176 9.68258 16.2337 9.68258 17.0813C9.68258 18.2892 8.59131 18.9229 7.48694 18.9229C6.7114 18.9229 6.16511 18.5915 5.59131 18.2232C5.59131 18.2232 6.04327 17.2991 6.16511 17.0813C6.42712 17.3242 6.9341 17.6265 7.45812 17.6265C7.85899 17.6265 8.25463 17.4866 8.25463 17.1842C8.25463 16.9479 8.21795 16.8384 7.67952 16.6311C7.5079 16.5651 7.09917 16.4027 6.86205 16.2932C6.16511 15.9684 5.7852 15.4601 5.7852 14.6641C5.78913 13.5367 6.70092 12.8661 7.6769 12.8661Z" fill="white"/>
        <path d="M11.5887 13.072V16.5361C11.5887 17.2872 12.0341 17.5526 12.5791 17.5526C13.1031 17.5526 13.5564 17.2648 13.5564 16.61V13.072H15.0053V16.6826C15.0053 17.9724 14.1511 18.8793 12.5503 18.8793C11.3372 18.8793 10.1529 18.2311 10.1529 16.845V13.072H11.5887Z" fill="white"/>
        <path d="M15.8594 13.072H18.135C19.1188 13.072 19.6926 13.6397 19.6926 14.4648C19.702 14.7155 19.6374 14.9634 19.5071 15.1772C19.3768 15.391 19.1865 15.5612 18.9603 15.6661C19.4555 15.777 20.0804 16.1229 20.0804 17.0298C20.0804 18.2377 19.1909 18.7235 18.228 18.7235C17.311 18.7235 15.8594 18.7169 15.8594 18.7169V13.072ZM17.2664 15.2463H17.6856C18.0014 15.2463 18.2961 15.1209 18.2961 14.6786C18.2961 14.2667 17.9738 14.1638 17.6568 14.1638H17.2638L17.2664 15.2463ZM17.2664 17.5961H17.9345C18.3 17.5961 18.6446 17.4641 18.6446 16.9559C18.6446 16.4912 18.2712 16.3512 17.9057 16.3512H17.2664V17.5961Z" fill="white"/>
        <path d="M22.1621 12.8661C22.8721 12.8661 23.5258 13.2054 23.9201 13.4707L23.3607 14.5757C23.3607 14.5757 22.7869 14.1268 22.2551 14.1268C21.904 14.1268 21.7022 14.3248 21.7022 14.6205C21.7022 14.9163 22.0402 15.0258 22.3271 15.1354C22.4987 15.2027 22.7791 15.3202 22.9232 15.3783C23.7485 15.7176 24.1717 16.2337 24.1717 17.0813C24.1717 18.2892 23.0804 18.9229 21.9747 18.9229C21.2005 18.9229 20.6542 18.5915 20.0804 18.2232C20.0804 18.2232 20.5324 17.2991 20.6542 17.0813C20.9162 17.3242 21.4219 17.6265 21.9459 17.6265C22.3481 17.6265 22.7437 17.4866 22.7437 17.1842C22.7437 16.9479 22.707 16.8384 22.1686 16.6311C21.997 16.5651 21.5869 16.4027 21.3511 16.2932C20.6542 15.9684 20.2743 15.4601 20.2743 14.6641C20.2743 13.5367 21.1861 12.8661 22.1621 12.8661Z" fill="white"/>
        <path d="M24.638 18.7169V13.072H26.0791V18.7169H24.638Z" fill="white"/>
        <path d="M26.928 13.072H29.2245C30.7035 13.072 31.838 14.1994 31.838 15.8285C31.838 17.4786 30.7677 18.7169 29.2389 18.7169H26.928V13.072ZM28.3848 17.4496H29.0162C29.869 17.4496 30.3931 16.7565 30.3931 15.8126C30.3931 14.9506 29.8337 14.3103 29.0647 14.3103H28.39L28.3848 17.4496Z" fill="white"/>
        <path d="M32.3974 18.7169V13.072H33.8385V18.7169H32.3974Z" fill="white"/>
        <path d="M7.18301 21.2806H5.7079V19.5367H10.5983V21.2806H9.1245V26.8766H7.18301V21.2806Z" fill="white"/>
        <path d="M11.0044 19.5275H15.1481V21.1948H12.8765V22.3829H15.0223V24.012H12.8765V25.2384H15.1965V26.8674H11.0044V19.5275Z" fill="white"/>
        <path d="M18.1022 26.8674H16.2354V19.5275H19.428C20.6974 19.5275 21.6773 20.4186 21.6773 21.9895C21.6773 23.4852 20.6974 24.309 19.483 24.309H18.1022V26.8674ZM18.0655 22.9004H18.7206C19.2904 22.9004 19.8223 22.7182 19.8223 21.913C19.8223 21.1856 19.3559 20.9743 18.7402 20.9743H18.0655V22.9004Z" fill="white"/>
        <path d="M21.7048 26.8674L24.3551 19.5367L26.4931 19.5275L29.1433 26.8674H27.1271L26.6424 25.5064H24.169L23.6922 26.8674H21.7048ZM24.6354 24.0304H26.1564L25.4005 21.7862L24.6354 24.0304Z" fill="white"/>
        <path d="M30.4586 21.2806H28.9835V19.5367H33.8752V21.2806H32.4V26.8766H30.4586V21.2806Z" fill="white"/>
        <mask height="40" id="mask0_914_3" maskUnits="userSpaceOnUse" width="40" x="0" y="0">
          <path d="M32.7721 0.134766H6.79916C3.06364 0.134766 0.0354004 3.18632 0.0354004 6.95061V33.1235C0.0354004 36.8878 3.06364 39.9394 6.79916 39.9394H32.7721C36.5076 39.9394 39.5358 36.8878 39.5358 33.1235V6.95061C39.5358 3.18632 36.5076 0.134766 32.7721 0.134766Z" fill="white"/>
        </mask>
        <g opacity={0.7}>
          <path d="M1.17904 8.18094C-0.065498 8.18094 -0.960258 7.60669 -1.75021 7.09975L-1.76855 7.08919L-1.75807 7.06939C-1.75807 7.05487 -1.05982 5.63573 -0.873795 5.31098L-0.859384 5.28457L-0.837114 5.30438C-0.4179 5.68853 0.351096 6.13738 1.1345 6.13738C1.73319 6.13738 2.33712 5.93408 2.33712 5.47731C2.33712 5.13408 2.30306 4.96774 1.46856 4.64959C1.16725 4.53342 0.562013 4.29183 0.208301 4.12154C-0.915716 3.59348 -1.46331 2.77104 -1.46331 1.59348C-1.47109 1.20778 -1.39489 0.825041 -1.24007 0.472205C-1.08526 0.11937 -0.855597 -0.194992 -0.567245 -0.44876C-0.000921368 -0.934625 0.72092 -1.19726 1.46463 -1.18803C2.62009 -1.18803 3.66813 -0.603215 4.18428 -0.25338L4.20262 -0.241499L3.31834 1.50372L3.29607 1.48523C3.2869 1.47863 2.40786 0.798765 1.60874 0.798765C1.09913 0.798765 0.78603 1.08127 0.78603 1.5354C0.78603 1.95124 1.21179 2.11098 1.62708 2.26807L1.72795 2.30636C1.92446 2.38292 2.21005 2.50041 2.438 2.59546L2.64498 2.68127C3.96682 3.22385 4.58516 4.06345 4.58516 5.32286C4.58516 7.19876 2.869 8.18094 1.17904 8.18094ZM-1.70305 7.07203C-0.917026 7.57236 -0.037987 8.12814 1.17904 8.12814C2.84673 8.12814 4.53538 7.16312 4.53538 5.31758C4.53407 4.06345 3.94586 3.27137 2.62664 2.72484L2.42096 2.63903C2.19171 2.54398 1.90743 2.42649 1.71092 2.35124L1.61005 2.31296C1.2 2.15718 0.734939 1.9816 0.734939 1.5354C0.734939 1.05091 1.069 0.75124 1.60874 0.75124C2.35808 0.75124 3.15721 1.32286 3.30131 1.43111L4.14105 -0.225658C3.61703 -0.572852 2.59651 -1.14051 1.46463 -1.14051C0.732451 -1.14991 0.0217181 -0.891479 -0.535804 -0.413116C-0.820177 -0.162653 -1.04644 0.147742 -1.19857 0.496093C-1.3507 0.844445 -1.42498 1.22224 -1.41615 1.60273C-1.41615 2.75916 -0.879035 3.57236 0.226642 4.08721C0.579043 4.25223 1.18297 4.49249 1.48428 4.61527C2.30568 4.92946 2.38297 5.10504 2.38297 5.48787C2.38297 5.97368 1.75546 6.1915 1.13319 6.1915C0.347166 6.1915 -0.4179 5.7519 -0.848904 5.36642C-1.04017 5.70306 -1.62707 6.90438 -1.70305 7.07203Z" fill="white"/>
          <path d="M8.96594 8.11362C7.97817 8.11362 7.07424 7.83903 6.40612 7.34266C6.03662 7.06937 5.73852 6.70972 5.53748 6.29465C5.33643 5.87957 5.23845 5.4215 5.25197 4.95982V-0.869882H7.50917V4.48325C7.50917 5.75718 8.32533 6.02385 9.01048 6.02385C9.72446 6.02385 10.4882 5.64761 10.4882 4.5915V-0.869882H12.7677V4.71032C12.7677 6.80933 11.3109 8.11362 8.96594 8.11362ZM5.29913 -0.821037V4.96114C5.29913 6.50702 6.43363 8.06741 8.96725 8.06741C10.1463 8.06741 11.1092 7.74266 11.7642 7.12748C12.3904 6.54002 12.7218 5.70438 12.7218 4.71164V-0.821037H10.5367V4.5915C10.5367 5.67929 9.75066 6.06741 9.01048 6.06741C8.01092 6.06741 7.46201 5.5024 7.46201 4.48325V-0.821037H5.29913Z" fill="white"/>
          <path d="M17.7039 7.87467C16.3061 7.87467 14.0804 7.86411 14.0581 7.86411H14.0345V-0.869882H17.5546C19.0258 -0.869882 19.9755 -0.0197172 19.9755 1.29645C19.9898 1.67542 19.8958 2.05056 19.7047 2.37725C19.5136 2.70395 19.2334 2.96847 18.8974 3.13936C19.5524 3.30041 20.5769 3.7849 20.5769 5.25157C20.5769 7.18292 19.0926 7.87467 17.7039 7.87467ZM14.0817 7.81659C14.324 7.81659 16.3834 7.82715 17.7039 7.82715C19.0703 7.82715 20.5297 7.1486 20.5297 5.24233C20.5297 3.76906 19.462 3.31098 18.8266 3.1684L18.7624 3.15388L18.8214 3.12352C19.1668 2.9645 19.4573 2.70512 19.6554 2.37865C19.8536 2.05218 19.9505 1.6735 19.9336 1.29117C19.9336 -0.0157568 19.0245 -0.821037 17.5598 -0.821037H14.0817V7.81659ZM17.2507 6.14002H16.2026V4.17566H17.2087C17.5585 4.17566 18.3707 4.26939 18.3707 5.13012C18.3681 5.79943 17.9921 6.14002 17.2507 6.14002ZM16.2445 6.09249H17.248C17.9581 6.09249 18.3183 5.76774 18.3183 5.13012C18.3183 4.79084 18.1729 4.22319 17.2035 4.22319H16.2445V6.09249ZM16.8721 2.52814H16.1974V0.811966H16.8275C17.4825 0.811966 17.8349 1.09447 17.8349 1.62913C17.8376 2.36708 17.3135 2.52286 16.8747 2.52286L16.8721 2.52814ZM16.2445 2.47929H16.8721C17.29 2.47929 17.7891 2.33144 17.7891 1.62913C17.7891 0.992824 17.2651 0.859491 16.8288 0.859491H16.2445V2.47929Z" fill="white"/>
          <path d="M23.4694 8.18094C22.2249 8.18094 21.3301 7.60669 20.5402 7.09975L20.5231 7.08919L20.5323 7.06939C20.5389 7.05487 21.2306 5.63573 21.4166 5.31098L21.431 5.28457L21.4533 5.30438C21.8725 5.68853 22.6415 6.13738 23.4249 6.13738C24.0236 6.13738 24.6275 5.93408 24.6275 5.47731C24.6275 5.13408 24.5935 4.96774 23.759 4.64959C23.4576 4.53342 22.8524 4.29183 22.4987 4.12154C21.3747 3.59348 20.8271 2.77104 20.8271 1.59348C20.8181 1.20613 20.8939 0.821536 21.049 0.466993C21.204 0.11245 21.4347 -0.203352 21.7245 -0.458001C22.2908 -0.943866 23.0126 -1.2065 23.7563 -1.19727C24.9144 -1.19727 25.9572 -0.612456 26.476 -0.262621L26.4943 -0.25074L25.61 1.49447L25.5878 1.47599C25.5786 1.46939 24.7009 0.789524 23.9004 0.789524C23.3908 0.789524 23.0738 1.07203 23.0738 1.52616C23.0738 1.93936 23.5035 2.10174 23.9188 2.25883L24.0197 2.29711C24.2162 2.37368 24.5018 2.49117 24.731 2.58622L24.9367 2.67203C26.2585 3.21461 26.8742 4.05421 26.8742 5.3123C26.8742 7.19876 25.1607 8.18094 23.4694 8.18094ZM20.5873 7.07203C21.3734 7.57236 22.2524 8.12814 23.4694 8.12814C25.1384 8.12814 26.8271 7.16312 26.8271 5.31758C26.8271 4.06213 26.2376 3.26081 24.9183 2.71956L24.7127 2.63375C24.4834 2.5387 24.1991 2.42121 24.0026 2.34596L23.9017 2.30768C23.4917 2.1519 23.0266 1.97632 23.0266 1.53012C23.0266 1.04563 23.3607 0.74596 23.9004 0.74596C24.6432 0.74596 25.4489 1.31758 25.593 1.42583L26.4328 -0.230938C25.9087 -0.578133 24.8882 -1.14579 23.7563 -1.14579C23.0242 -1.15519 22.3134 -0.89676 21.7559 -0.418397C21.4715 -0.167933 21.2453 0.142461 21.0931 0.490813C20.941 0.839165 20.8667 1.21696 20.8756 1.59744C20.8756 2.75388 21.4127 3.56708 22.5183 4.08193C22.8707 4.24695 23.4747 4.48721 23.776 4.60999C24.5974 4.92418 24.676 5.09975 24.676 5.48259C24.676 5.9684 24.0472 6.18622 23.4249 6.18622C22.6389 6.18622 21.8738 5.74662 21.4428 5.36114C21.2515 5.70306 20.6646 6.90438 20.5834 7.07203H20.5873Z" fill="white"/>
          <path d="M29.8009 7.86411H27.5437V-0.869882H29.8009V7.86411ZM27.5908 7.81527H29.7537V-0.821037H27.5908V7.81527Z" fill="white"/>
          <path d="M34.6493 7.86411H31.0677V-0.869882H34.6271C36.9707 -0.869882 38.6712 0.922857 38.6712 3.39414C38.6712 5.98424 36.9799 7.86411 34.6493 7.86411ZM31.1122 7.81527H34.6493C36.9524 7.81527 38.624 5.95652 38.624 3.39414C38.624 0.95058 36.9432 -0.821037 34.6271 -0.821037H31.1148L31.1122 7.81527ZM34.3061 5.91164H33.3105V1.02715H34.3729C35.5703 1.02715 36.4402 2.01065 36.4402 3.3651C36.4402 4.88721 35.5821 5.91164 34.3061 5.91164ZM33.3576 5.86411H34.3061C35.5546 5.86411 36.393 4.85817 36.393 3.35586C36.393 2.03573 35.5428 1.06543 34.3729 1.06543H33.3576V5.86411Z" fill="white"/>
          <path d="M41.7419 7.86411H39.4847V-0.869882H41.7419V7.86411ZM39.5332 7.81527H41.6948V-0.821037H39.5332V7.81527Z" fill="white"/>
          <path d="M3.7166 19.7255H0.681227V11.1143H-1.58777V8.38292H5.98559V11.1143H3.7166V19.7255ZM0.728389 19.678H3.66813V11.0668H5.93843V8.42649H-1.54061V11.0668H0.728389V19.678Z" fill="white"/>
          <path d="M13.052 19.711H6.56987V8.36708H12.9891V10.9809H9.48996V12.7618H12.7939V15.3163H9.48996V17.1565H13.052V19.711ZM6.61703 19.6621H13.0048V17.204H9.4428V15.2687H12.7454V12.8093H9.4428V10.9281H12.9472V8.41989H6.61703V19.6621Z" fill="white"/>
          <path d="M17.5336 19.711H14.6135V8.36708H19.5485C20.0073 8.356 20.4636 8.43845 20.8899 8.6095C21.3163 8.78055 21.704 9.03666 22.0297 9.36246C22.6847 10.045 23.0345 11.0205 23.0345 12.1809C23.0345 14.3301 21.6681 15.773 19.6284 15.773H17.5323L17.5336 19.711ZM14.6607 19.6621H17.4852V15.7255H19.6349C21.6406 15.7255 22.9873 14.3011 22.9873 12.1809C22.9873 11.0324 22.6441 10.0687 21.9956 9.39546C21.3773 8.75388 20.531 8.41989 19.5485 8.41989H14.6607V19.6621ZM18.4598 13.6054H17.4236V10.5941H18.4808C19.6179 10.5941 20.1707 11.0747 20.1707 12.0635C20.1747 13.1011 19.614 13.6054 18.4572 13.6054H18.4598ZM17.4786 13.5578H18.4598C19.5852 13.5578 20.1314 13.0694 20.1314 12.0635C20.1314 11.0932 19.6074 10.6417 18.4887 10.6417H17.476L17.4786 13.5578Z" fill="white"/>
          <path d="M34.5341 19.711H31.3821L31.3755 19.6951L30.6354 17.6159H26.8624L26.1288 19.711H23.0188L23.0306 19.678L27.1179 8.38292H27.1349L30.4402 8.36708V8.38292L34.5341 19.711ZM31.4149 19.6621H34.4673L30.4048 8.41989L27.1493 8.43441L23.0882 19.6661H26.1013L26.8336 17.5724H30.6681V17.5882L31.4149 19.6621ZM29.9371 15.3453H27.5306L28.7411 11.7981L29.9371 15.3453ZM27.5961 15.2978H29.869L28.7384 11.9446L27.5961 15.2978Z" fill="white"/>
          <path d="M39.5371 19.7255H36.5018V11.1143H34.2328V8.38292H41.8061V11.1143H39.5371V19.7255ZM36.5502 19.678H39.4913V11.0668H41.7603V8.42649H34.2799V11.0668H36.5502V19.678Z" fill="white"/>
          <path d="M1.17904 29.278C-0.065498 29.278 -0.960258 28.7037 -1.75021 28.1968L-1.76855 28.1849L-1.75807 28.1664C-1.75807 28.1519 -1.05982 26.7328 -0.873795 26.4067L-0.859384 26.3816L-0.837114 26.4014C-0.4179 26.7856 0.351096 27.2344 1.1345 27.2344C1.73319 27.2344 2.33712 27.0311 2.33712 26.5743C2.33712 26.2298 2.30306 26.0648 1.46856 25.7453C1.16725 25.6291 0.562013 25.3889 0.208301 25.2238C-0.915716 24.6958 -1.46331 23.8734 -1.46331 22.6958C-1.47244 22.3085 -1.39689 21.924 -1.24203 21.5695C-1.08718 21.2149 -0.856799 20.8991 -0.567245 20.6443C-0.000679492 20.1589 0.721 19.8963 1.46463 19.905C2.62271 19.905 3.66551 20.4899 4.18428 20.8384L4.20262 20.8503L3.31834 22.5955L3.29607 22.5783C3.2869 22.5783 2.40786 21.8918 1.60874 21.8918C1.09913 21.8918 0.7821 22.173 0.7821 22.6271C0.7821 23.0417 1.21179 23.204 1.62708 23.3611L1.72795 23.3994C1.92446 23.4747 2.21005 23.5935 2.438 23.6885L2.64498 23.773C3.96682 24.3156 4.58254 25.1552 4.58254 26.4133C4.58516 28.2932 2.869 29.278 1.17904 29.278ZM-1.70305 28.1677C-0.917026 28.6681 -0.037987 29.2304 1.17904 29.2304C2.84673 29.2304 4.53538 28.2641 4.53538 26.4186C4.53538 25.1644 3.94717 24.3631 2.62795 23.8219L2.42096 23.7334C2.19171 23.6384 1.90743 23.5209 1.71092 23.4443L1.61005 23.406C1.2 23.2516 0.734939 23.076 0.734939 22.6285C0.734939 22.1453 1.069 21.8456 1.60874 21.8456C2.35153 21.8456 3.15721 22.4172 3.30131 22.5242L4.14105 20.8687C3.61703 20.5215 2.59651 19.9539 1.46463 19.9539C0.732451 19.9445 0.0217181 20.2029 -0.535804 20.6813C-0.820177 20.9317 -1.04644 21.2421 -1.19857 21.5905C-1.3507 21.9388 -1.42498 22.3166 -1.41615 22.6971C-1.41615 23.8535 -0.879035 24.6668 0.226642 25.1816C0.579043 25.3453 1.18297 25.5869 1.48428 25.7031C2.30568 26.0172 2.38297 26.1915 2.38297 26.5757C2.38297 27.0615 1.75546 27.2793 1.13319 27.2793C0.347166 27.2793 -0.4179 26.8397 -0.848904 26.4542C-1.04017 26.7974 -1.62707 27.9988 -1.70305 28.1651V28.1677Z" fill="white"/>
          <path d="M8.96594 29.2067C7.97817 29.2067 7.07031 28.9334 6.40612 28.437C6.03659 28.1635 5.73851 27.8036 5.53747 27.3883C5.33644 26.973 5.23846 26.5148 5.25197 26.0529V20.2232H7.50917V25.5816C7.50917 26.8555 8.32533 27.1222 9.01048 27.1222C9.72446 27.1222 10.4882 26.7473 10.4882 25.6951V20.2232H12.7677V25.8034C12.7677 27.9037 11.3109 29.2067 8.96594 29.2067ZM5.29782 20.272V26.0529C5.29782 27.6001 6.43232 29.1592 8.96594 29.1592C10.145 29.1592 11.1079 28.8344 11.7629 28.2205C12.3891 27.6331 12.7205 26.7974 12.7205 25.8034V20.272H10.5367V25.6912C10.5367 26.779 9.75066 27.1658 9.01048 27.1658C8.01092 27.1658 7.46725 26.6021 7.46725 25.5816V20.272H5.29782Z" fill="white"/>
          <path d="M17.7039 28.9691C16.3061 28.9691 14.0804 28.9572 14.0581 28.9572H14.0345V20.2232H17.5546C19.0258 20.2232 19.9755 21.0747 19.9755 22.3908C19.9886 22.7697 19.8934 23.1444 19.7014 23.4704C19.5093 23.7964 19.2285 24.0599 18.8921 24.2298C19.5472 24.3908 20.5716 24.8767 20.5716 26.342C20.5769 28.2773 19.0926 28.9691 17.7039 28.9691ZM14.0817 28.9097C14.324 28.9097 16.3834 28.9215 17.7039 28.9215C19.0703 28.9215 20.5297 28.2417 20.5297 26.3367C20.5297 24.8635 19.462 24.404 18.8266 24.2628L18.7624 24.2483L18.8214 24.2179C19.1662 24.0594 19.4564 23.801 19.6547 23.4755C19.853 23.1501 19.9506 22.7724 19.9349 22.3908C19.9349 21.0839 19.0258 20.272 17.5611 20.272H14.0817V28.9097ZM17.2507 27.2344H16.2026V25.2701H17.2087C17.5585 25.2701 18.3707 25.3625 18.3707 26.2232C18.3681 26.8938 17.9921 27.2344 17.2507 27.2344ZM16.2472 27.1856H17.2507C17.9607 27.1856 18.321 26.8621 18.321 26.2232C18.321 25.8852 18.1755 25.3176 17.2061 25.3176H16.2445L16.2472 27.1856ZM16.8747 23.6212H16.2V21.905H16.8301C17.4852 21.905 17.8376 22.1875 17.8376 22.7222C17.8376 23.4615 17.3135 23.6172 16.8747 23.6172V23.6212ZM16.2472 23.5724H16.8747C17.2926 23.5724 17.7917 23.4258 17.7917 22.7235C17.7917 22.0872 17.2677 21.9539 16.8314 21.9539H16.2445L16.2472 23.5724Z" fill="white"/>
          <path d="M23.4694 29.278C22.2249 29.278 21.3301 28.7037 20.5402 28.1968L20.5231 28.1849L20.5323 28.1664C20.5389 28.1519 21.2306 26.7328 21.4166 26.4067L21.431 26.3816L21.4533 26.4014C21.8725 26.7856 22.6415 27.2344 23.4249 27.2344C24.0236 27.2344 24.6275 27.0311 24.6275 26.5743C24.6275 26.2298 24.5935 26.0648 23.759 25.7453C23.4576 25.6291 22.8524 25.3889 22.4987 25.2238C21.3747 24.6958 20.8271 23.8734 20.8271 22.6958C20.8179 22.3082 20.8936 21.9234 21.0487 21.5686C21.2038 21.2138 21.4345 20.8978 21.7245 20.643C22.2912 20.158 23.0129 19.8959 23.7563 19.905C24.9144 19.905 25.9572 20.4899 26.476 20.8384L26.4943 20.8503L25.61 22.5955L25.5878 22.5783C25.5786 22.5783 24.7009 21.8918 23.9004 21.8918C23.3908 21.8918 23.0738 22.173 23.0738 22.6271C23.0738 23.0417 23.5035 23.204 23.9188 23.3611L24.0197 23.3994C24.2162 23.4747 24.5018 23.5935 24.731 23.6885L24.9367 23.773C26.2585 24.3156 26.8742 25.1552 26.8742 26.4133C26.8742 28.2932 25.1607 29.278 23.4694 29.278ZM20.5873 28.1677C21.3734 28.6681 22.2524 29.2304 23.4694 29.2304C25.1384 29.2304 26.8271 28.2641 26.8271 26.4186C26.8271 25.1644 26.2376 24.3631 24.9183 23.8219L24.7127 23.7361C24.4834 23.641 24.1991 23.5235 24.0026 23.4469L23.9017 23.4087C23.4917 23.2542 23.0266 23.0786 23.0266 22.6311C23.0266 22.1479 23.3607 21.8483 23.9004 21.8483C24.6432 21.8483 25.4489 22.4199 25.593 22.5268L26.4328 20.8714C25.9087 20.5242 24.8882 19.9565 23.7563 19.9565C23.0242 19.9471 22.3134 20.2055 21.7559 20.6839C21.4715 20.9344 21.2453 21.2448 21.0931 21.5931C20.941 21.9415 20.8667 22.3193 20.8756 22.6998C20.8756 23.8562 21.4127 24.6694 22.5183 25.1842C22.8707 25.3479 23.4747 25.5895 23.776 25.7057C24.5974 26.0199 24.676 26.1941 24.676 26.5783C24.676 27.0641 24.0472 27.2819 23.4249 27.2819C22.6389 27.2819 21.8738 26.8423 21.4428 26.4569C21.2515 26.7974 20.6646 27.9988 20.5834 28.1651L20.5873 28.1677Z" fill="white"/>
          <path d="M29.8009 28.9572H27.5437V20.2232H29.8009V28.9572ZM27.5908 28.9097H29.7537V20.272H27.5908V28.9097Z" fill="white"/>
          <path d="M34.6493 28.9572H31.0677V20.2232H34.6271C36.9707 20.2232 38.6712 22.0172 38.6712 24.4885C38.6712 27.0786 36.9799 28.9572 34.6493 28.9572ZM31.1122 28.9097H34.6493C36.9524 28.9097 38.624 27.0509 38.624 24.4885C38.624 22.045 36.9432 20.272 34.6271 20.272H31.1148L31.1122 28.9097ZM33.3079 27.0073V22.1229H34.3703C35.5677 22.1229 36.4376 23.1064 36.4376 24.4595C36.4402 25.9776 35.5821 27.0073 34.3061 27.0073H33.3079ZM33.355 26.9598H34.3035C35.552 26.9598 36.3904 25.9526 36.3904 24.4516C36.3904 23.1248 35.5402 22.1625 34.3703 22.1625H33.355V26.9598Z" fill="white"/>
          <path d="M41.7419 28.9572H39.4847V20.2232H41.7419V28.9572ZM39.5332 28.9097H41.6948V20.272H39.5332V28.9097Z" fill="white"/>
          <path d="M3.7166 40.8186H0.681227V32.2073H-1.58777V29.476H5.98559V32.2073H3.7166V40.8186ZM0.728389 40.771H3.66813V32.1598H5.93843V29.5196H-1.54061V32.1598H0.728389V40.771Z" fill="white"/>
          <path d="M13.052 40.804H6.56987V29.4615H12.9891V32.0753H9.48996V33.8562H12.7939V36.4067H9.48996V38.2549H13.052V40.804ZM6.61703 40.7631H13.0048V38.2971H9.4428V36.3631H12.7454V33.8984H9.4428V32.0278H12.9472V29.509H6.61703V40.7631Z" fill="white"/>
          <path d="M17.5336 40.804H14.6135V29.4615H19.5485C20.0073 29.4504 20.4636 29.5328 20.8899 29.7039C21.3163 29.8749 21.704 30.1311 22.0297 30.4568C22.6847 31.1394 23.0345 32.1136 23.0345 33.2753C23.0345 35.4232 21.6681 36.8674 19.6284 36.8674H17.5323L17.5336 40.804ZM14.6607 40.7631H17.4852V36.8199H19.6349C21.6406 36.8199 22.9873 35.3955 22.9873 33.2753C22.9873 32.1268 22.6441 31.1631 21.9956 30.4899C21.3773 29.8483 20.531 29.509 19.5485 29.509H14.6607V40.7631ZM18.4598 34.7064H17.4236V31.6885H18.4808C19.6179 31.6885 20.1707 32.1691 20.1707 33.1565C20.1747 34.1955 19.614 34.6998 18.4572 34.6998L18.4598 34.7064ZM17.4786 34.6588H18.4598C19.5852 34.6588 20.1314 34.1691 20.1314 33.1631C20.1314 32.1941 19.6074 31.7427 18.4887 31.7427H17.476L17.4786 34.6588Z" fill="white"/>
          <path d="M34.5341 40.804H31.3821L31.3755 40.7882L30.6354 38.7103H26.8624L26.1288 40.804H23.0188L23.0306 40.7724L27.1179 29.476H27.1349L30.4402 29.4615V29.4773L34.5341 40.804ZM31.4149 40.7631H34.4673L30.4061 29.5156L27.1507 29.5301L23.0895 40.7631H26.1026L26.8349 38.6694H30.6681V38.6852L31.4149 40.7631ZM29.9371 36.4463H27.5306L28.7411 32.8978L29.9371 36.4463ZM27.5961 36.3988H29.869L28.7384 33.0456L27.5961 36.3988Z" fill="white"/>
          <path d="M39.5371 40.8186H36.5018V32.2073H34.2328V29.476H41.8061V32.2073H39.5371V40.8186ZM36.5502 40.771H39.4913V32.1598H41.7603V29.5196H34.2799V32.1598H36.5502V40.771Z" fill="white"/>
        </g>
        <path d="M46.1135 17.41L50.0816 6.01855H52.9441L54.1362 12.2351L57.6969 6.01855H60.6786L60.2856 17.41H57.2725L57.6485 10.8661L53.8218 17.41H52.4345L51.1651 10.837L49.0991 17.41H46.1135Z" fill="white"/>
        <path d="M63.2751 6.01855H69.8253L69.3262 8.60601H65.7327L65.3764 10.4542H68.7773L68.2886 12.9823H64.8891L64.5223 14.8885H68.1825L67.6952 17.4166H61.0834L63.2751 6.01855Z" fill="white"/>
        <path d="M73.0729 13.3941L72.2987 17.41H69.3471L71.5441 6.01855H76.0454C78.0537 6.01855 79.6913 7.16311 79.2236 9.58291C78.9236 11.1446 78.0445 12.2007 76.6768 12.6918C76.8891 13.1235 78.7926 17.406 78.7926 17.406H75.2659C75.2659 17.406 73.8864 13.9156 73.6637 13.3941H73.0729ZM73.4476 11.1499H74.3921C75.2044 11.1499 75.9786 10.808 76.1882 9.72285C76.3755 8.75519 75.9458 8.28786 74.9725 8.28786H73.9978L73.4476 11.1499Z" fill="white"/>
        <path d="M86.8087 5.69116C88.0035 5.69116 88.7135 5.84034 89.6515 6.40535L89.0554 9.49842C88.4856 8.93341 87.8161 8.50172 86.4144 8.50172C84.4952 8.50172 83.1602 9.91426 82.7869 11.8483C82.4345 13.6766 83.5428 14.8964 85.3284 14.8964C86.4196 14.8964 87.3144 14.4648 88.1646 13.9591L87.5882 16.9492C86.5782 17.3651 85.7751 17.707 84.5934 17.707C81.3327 17.707 79.1345 15.3308 79.8118 11.8179C80.5153 8.17433 83.4878 5.69116 86.8087 5.69116Z" fill="white"/>
        <path d="M91.0546 6.01855H94.0061L93.1795 10.3011H97.3127L98.138 6.01855H101.091L98.8926 17.41H95.941L96.8332 12.7895H92.7013L91.8092 17.41H88.8576L91.0546 6.01855Z" fill="white"/>
        <path d="M99.6943 17.41L106.081 6.03308L109.463 6.01855L111.457 17.41H108.265L107.904 15.2978H103.993L102.833 17.41H99.6943ZM105.178 13.0073H107.583L107.059 9.52747L105.178 13.0073Z" fill="white"/>
        <path d="M115.245 17.41H112.293L114.49 6.01855H117.441L121.423 12.7763L122.725 6.02516H125.589L123.39 17.41H120.439L116.556 10.6139L115.245 17.41Z" fill="white"/>
        <path d="M128.059 8.73935H125.727L126.252 6.03308H133.981L133.457 8.73935H131.135L129.46 17.4245H126.384L128.059 8.73935Z" fill="white"/>
        <path d="M143.742 17.41L143.319 15.0905H138.603L137.255 17.41H134.996L141.651 6.01855H143.997L146.125 17.41H143.742ZM142.314 8.51624L139.584 13.3347H143.057L142.314 8.51624Z" fill="white"/>
        <path d="M151.585 12.9334H149.814L148.952 17.41H146.797L148.995 6.01855H152.891C154.75 6.01855 156.027 7.35717 155.625 9.43902C155.209 11.5948 153.46 12.9334 151.585 12.9334ZM151.99 7.69908H150.794L150.118 11.208H151.428C152.314 11.208 153.206 10.7182 153.449 9.45354C153.705 8.14529 152.963 7.69908 151.99 7.69908Z" fill="white"/>
        <path d="M159.825 12.9334H158.054L157.191 17.41H155.031L157.229 6.01855H161.125C162.984 6.01855 164.261 7.35717 163.859 9.43902C163.444 11.5948 161.695 12.9334 159.825 12.9334ZM160.23 7.69908H159.034L158.358 11.208H159.668C160.554 11.208 161.446 10.7182 161.69 9.45354C161.94 8.14529 161.198 7.69908 160.225 7.69908H160.23Z" fill="white"/>
        <path d="M165.888 17.7374C164.369 17.7374 163.445 17.3202 162.613 16.1611L164.202 14.7327C164.422 15.0649 164.722 15.3349 165.074 15.5172C165.426 15.6996 165.819 15.7881 166.214 15.7743C167.086 15.7743 167.991 15.1341 168.16 14.2575C168.301 13.5288 167.898 13.0522 167.261 12.8054C166.974 12.6865 166.643 12.5677 166.26 12.4027C164.494 11.6106 164.055 10.7525 164.359 9.17631C164.735 7.2278 166.611 5.84562 168.485 5.84562C169.813 5.84562 170.755 6.32086 171.367 7.18291L169.896 8.59149C169.543 8.20469 169.133 7.72813 168.203 7.72813C167.465 7.72813 166.665 8.35255 166.52 9.11162C166.389 9.79545 166.788 10.1677 167.329 10.4199C167.75 10.6139 167.992 10.7327 168.415 10.911C169.877 11.5209 170.653 12.6271 170.332 14.2865C169.945 16.2945 167.836 17.7374 165.888 17.7374Z" fill="white"/>
        <path d="M48.1967 33.3347H46.3076L47.7146 26.0436H50.9452C52.2303 26.0436 53.0504 26.9295 52.7504 28.4899C52.4635 29.975 51.3094 30.7935 50.0858 30.7935H48.6867L48.1967 33.3347ZM48.9198 29.3941H49.5749C50.1513 29.3941 50.7251 29.2133 50.8849 28.4133C51.0238 27.6899 50.5915 27.4813 49.9679 27.4813H49.288L48.9198 29.3941Z" fill="#1872B3"/>
        <path d="M51.8438 33.3347L55.9312 26.0529L58.0967 26.0436L59.3727 33.3347H57.3316L57.1023 31.9829H54.5975L53.8561 33.3347H51.8438ZM55.3534 30.5176H56.8927L56.5574 28.2905L55.3534 30.5176Z" fill="#1872B3"/>
        <path d="M61.8002 33.3347H59.9111L61.3181 26.0436H63.2072L65.7552 30.3658L66.5897 26.0436H68.4238L67.0168 33.3347H65.1264L62.6373 28.9849L61.8002 33.3347Z" fill="#1872B3"/>
        <path d="M72.9696 25.777C74.425 25.777 75.3827 26.3486 75.8857 27.6054L74.2128 28.4423C74.2128 28.4423 73.9167 27.4298 72.6316 27.4298C71.3936 27.4298 70.6403 28.4476 70.3848 29.7717C70.1464 31.0087 70.5224 31.9988 71.9202 31.9988C72.7233 31.9988 73.58 31.3796 73.7765 30.7037H72.1128L72.4076 29.1803H76.0351C76.0351 29.1803 75.9866 29.4285 75.9578 29.5763C75.5412 31.7374 74.1905 33.5935 71.48 33.5935C69.1744 33.5935 68.0438 31.8139 68.4538 29.6912C68.8848 27.4615 70.863 25.777 72.9696 25.777Z" fill="#1872B3"/>
        <path d="M77.7945 33.3348H75.9054L77.3111 26.0437H79.2015L78.6028 29.146L81.3172 26.0424H83.5652L80.3124 29.4879L82.6508 33.3334H80.2128L78.4102 30.1453L77.7945 33.3348Z" fill="#1872B3"/>
        <path d="M82.4334 33.3347L86.5207 26.0529L88.6901 26.0436L89.9661 33.3347H87.9211L87.6919 31.9829H85.1871L84.4456 33.3347H82.4334ZM85.939 30.5176H87.4783L87.143 28.2905L85.939 30.5176Z" fill="#1872B3"/>
        <path d="M90.5006 33.3347L91.9076 26.0436H93.7993L92.712 31.6793H94.998L94.6796 33.3347H90.5006Z" fill="#1872B3"/>
        <path d="M94.7424 33.3347L98.8297 26.0529L100.995 26.0436L102.271 33.3347H100.231L100.001 31.9829H97.4974L96.7546 33.3347H94.7424ZM98.2533 30.5176H99.7939L99.4572 28.2905L98.2533 30.5176Z" fill="#1872B3"/>
        <path d="M104.7 33.3347H102.81L104.217 26.0436H106.106L108.654 30.3658L109.488 26.0436H111.322L109.913 33.3347H108.025L105.536 28.9849L104.7 33.3347Z" fill="#1872B3"/>
        <path d="M115.271 33.3348H113.382L114.787 26.0437H118.019C119.303 26.0437 120.124 26.9295 119.823 28.4899C119.537 29.9751 118.382 30.7935 117.158 30.7935H115.761L115.271 33.3348ZM115.993 29.3942H116.654C117.231 29.3942 117.804 29.2133 117.958 28.4133C118.098 27.6899 117.665 27.4813 117.041 27.4813H116.361L115.993 29.3942Z" fill="#C1272D"/>
        <path d="M120.929 26.0437H125.121L124.801 27.7005H122.498L122.27 28.882H124.444L124.132 30.5005H121.965L121.729 31.7189H124.061L123.749 33.3361H119.522L120.929 26.0437Z" fill="#C1272D"/>
        <path d="M127.197 30.7645L126.701 33.3348H124.812L126.219 26.0437H129.101C130.386 26.0437 131.435 26.7764 131.135 28.3288C130.943 29.3282 130.378 30.0041 129.504 30.3183C129.635 30.5942 130.859 33.3348 130.859 33.3348H128.6C128.6 33.3348 127.719 31.0985 127.575 30.7645H127.197ZM127.437 29.3282H128.041C128.565 29.3282 129.056 29.109 129.191 28.4133C129.31 27.7955 129.037 27.4998 128.413 27.4998H127.789L127.437 29.3282Z" fill="#C1272D"/>
        <path d="M133.072 27.785H131.579L131.913 26.0529H136.863L136.529 27.785H135.037L133.964 33.344H131.999L133.072 27.785Z" fill="#C1272D"/>
        <path d="M135.27 33.3348L139.357 26.0529L141.523 26.0437L142.799 33.3348H140.759L140.528 31.983H138.025L137.282 33.3348H135.27ZM138.779 30.5176H140.32L139.983 28.2906L138.779 30.5176Z" fill="#C1272D"/>
        <path d="M142.677 33.3348L145.217 26.0437H147.051L147.813 30.0226L150.093 26.0437H152L151.746 33.3348H149.819L150.06 29.1473L147.609 33.3348H146.725L145.911 29.1275L144.589 33.3348H142.677Z" fill="#C1272D"/>
        <path d="M152.256 33.3348L153.668 26.0437H155.558L154.151 33.3348H152.256Z" fill="#C1272D"/>
        <path d="M157.158 33.3348H155.269L156.681 26.0437H158.57L161.118 30.3658L161.953 26.0437H163.787L162.38 33.3348H160.48L157.991 28.985L157.158 33.3348Z" fill="#C1272D"/>
        <path d="M162.892 33.3348L166.981 26.0529L169.146 26.0437L170.422 33.3348H168.381L168.151 31.983H165.647L164.906 33.3348H162.892ZM166.403 30.5176H167.948L167.612 28.2906L166.403 30.5176Z" fill="#C1272D"/>
      </g>
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_914_3" x1="0" x2="180" y1="20.037" y2="20.037">
          <stop stopColor="#3FA9F5"/>
          <stop offset="0.47" stopColor="#1872B3"/>
          <stop offset="1" stopColor="#2952A0"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_914_3" x1="31.3101" x2="178.559" y1="11.2581" y2="11.2581">
          <stop stopColor="#3FA9F5"/>
          <stop offset="0.47" stopColor="#1872B3"/>
          <stop offset="1" stopColor="#2952A0"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint2_linear_914_3" x1="0.0341797" x2="39.5346" y1="20.0371" y2="20.0371">
          <stop stopColor="#3FA9F5"/>
          <stop offset="0.47" stopColor="#1872B3"/>
          <stop offset="1" stopColor="#2952A0"/>
        </linearGradient>
        <clipPath id="clip0_914_3">
          <rect fill="white" height="40" width="180"/>
        </clipPath>
      </defs>
    </svg>

  );
}

LogoMap.defaultProps = {
  className: ''
};
LogoMap.propTypes = {
  className: PropTypes.string,
};

