import React from 'react';
import PropTypes from 'prop-types';
export default function IconFailed({ className }){
  return(
    <svg className={className} fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20C0 31.0457 8.95431 40 20 40ZM26.3839 13.1161C26.872 13.6043 26.872 14.3957 26.3839 14.8839L21.7678 19.5L26.3839 24.1161C26.872 24.6043 26.872 25.3957 26.3839 25.8839C25.8957 26.372 25.1043 26.372 24.6161 25.8839L20 21.2678L15.3839 25.8839C14.8957 26.372 14.1043 26.372 13.6161 25.8839C13.128 25.3957 13.128 24.6043 13.6161 24.1161L18.2322 19.5L13.6161 14.8839C13.128 14.3957 13.128 13.6043 13.6161 13.1161C14.1043 12.628 14.8957 12.628 15.3839 13.1161L20 17.7322L24.6161 13.1161C25.1043 12.628 25.8957 12.628 26.3839 13.1161Z" fill="#FD3E38" fillRule="evenodd"/>
    </svg>

  );
}
IconFailed.defaultProps = {
  className: ''
};
IconFailed.propTypes = {
  className: PropTypes.string
};
