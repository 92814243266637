import { actionTypes } from './constants';
import { initialValues as filterTable } from '../../components/fragments/AktivasiMyPertaminaFilter/fields';

const { FAILED, FILTER, LOADING, MESSAGE, SUCCESS } = actionTypes;
const state = {
  data: null,
  loading: false,
  message: '',
  success: false,
  filter: {},
  meta: {},
  toastrType: 'danger'
};

export const initialState = {
  table: {
    ...state,
    data: [],
    filter: { ...filterTable },
    meta: {
      page: 1,
      size: 100,
      totalPage: 1,
      totalData: 0,
      summary: [],
    },
  },
  detail: { ...state, data: {} },
  download: state,
  activation: state,
  process: state,
};

export default function reducer(state = initialState, action) {
  const {
    type,
    data,
    loading,
    message = '',
    meta,
    initial = true,
    filter,
    key,
  } = action;
  const isTable = key === 'table';
  const tableData = initial ? data : [...state.table.data, ...data];

  switch (type) {
    case FAILED:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: initialState[key].data,
          loading: false,
          message,
          success: false,
          toastrType: 'danger'
        },
      };
    case LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          success: false,
          loading,
          message,
          toastrType: 'info'
        },
      };
    case SUCCESS:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: isTable ? tableData : data,
          meta,
          loading: false,
          message,
          success: true,
          toastrType: 'success'
        },
      };
    case FILTER:
      return {
        ...state,
        [key]: {
          ...state[key],
          filter,
        },
      };
    case MESSAGE:
      return {
        ...state,
        [key]: {
          ...state[key],
          success: false,
          loading: false,
          message,
        },
      };
    default:
      return state;
  }
}
