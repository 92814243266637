import React from 'react';
import PropTypes from 'prop-types';
export default function IconNonActive({ className }){
  return(
    <svg className={className} fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.897052 1.05379L0.96967 0.96967C1.23594 0.703403 1.6526 0.679197 1.94621 0.897052L2.03033 0.96967L5 3.939L7.96967 0.96967C8.26256 0.676777 8.73744 0.676777 9.03033 0.96967C9.32322 1.26256 9.32322 1.73744 9.03033 2.03033L6.061 5L9.03033 7.96967C9.2966 8.23594 9.3208 8.6526 9.10295 8.94621L9.03033 9.03033C8.76406 9.2966 8.3474 9.3208 8.05379 9.10295L7.96967 9.03033L5 6.061L2.03033 9.03033C1.73744 9.32322 1.26256 9.32322 0.96967 9.03033C0.676777 8.73744 0.676777 8.26256 0.96967 7.96967L3.939 5L0.96967 2.03033C0.703403 1.76406 0.679197 1.3474 0.897052 1.05379L0.96967 0.96967L0.897052 1.05379Z" fill="#CF212A"/>
    </svg>
  );
}
IconNonActive.defaultProps = {
  className: ''
};
IconNonActive.propTypes = {
  className: PropTypes.string
};
