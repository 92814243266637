import React from 'react';
import PropTypes from 'prop-types';
export default function IconRole({ className }){
  return(
    <svg className={className} fill="none" height="22" viewBox="0 0 21 22" width="21" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9999 12H11.9399C11.5999 15.1 9.67991 17.72 6.99991 19.05C6.96991 20.86 5.33991 22.28 3.44991 21.95C2.24991 21.74 1.25991 20.75 1.04991 19.55C0.709914 17.65 2.15991 16 3.99991 16C4.94991 16 5.77991 16.45 6.32991 17.14C8.22991 16.11 9.58991 14.23 9.90991 12H6.80991C6.32991 13.34 4.94991 14.24 3.38991 13.94C2.20991 13.71 1.25991 12.74 1.03991 11.56C0.699914 9.66 2.15991 8 3.99991 8C5.29991 8 6.39991 8.84 6.81991 10H9.91991C9.59991 7.77 8.22991 5.9 6.32991 4.86C5.68991 5.66 4.65991 6.14 3.51991 5.96C2.28991 5.77 1.25991 4.77 1.04991 3.54C0.719914 1.65 2.16991 0 3.99991 0C5.63991 0 6.95991 1.31 6.98991 2.95C9.66991 4.28 11.5899 6.9 11.9299 10H16.9999V8.21C16.9999 7.76 17.5399 7.54 17.8499 7.86L20.6399 10.65C20.8399 10.85 20.8399 11.16 20.6399 11.36L17.8499 14.15C17.5399 14.46 16.9999 14.24 16.9999 13.79V12Z" fillRule="evenodd"/>
    </svg>

  );
}
IconRole.defaultProps = {
  className: ''
};
IconRole.propTypes = {
  className: PropTypes.string
};
