import React from 'react';
import PropTypes from 'prop-types';
export default function IconSuccess({ className }){
  return(
    <svg className={className} fill="none" height="40" viewBox="0 0 41 40" width="41" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5 0C31.5457 0 40.5 8.9543 40.5 20C40.5 31.0457 31.5457 40 20.5 40C9.4543 40 0.5 31.0457 0.5 20C0.5 8.9543 9.4543 0 20.5 0ZM26.9393 13.9393L18 22.8787L14.0607 18.9393C13.4749 18.3536 12.5251 18.3536 11.9393 18.9393C11.3536 19.5251 11.3536 20.4749 11.9393 21.0607L16.9393 26.0607C17.5251 26.6464 18.4749 26.6464 19.0607 26.0607L29.0607 16.0607C29.6464 15.4749 29.6464 14.5251 29.0607 13.9393C28.4749 13.3536 27.5251 13.3536 26.9393 13.9393Z" fill="#44AB49"/>
    </svg>

  );
}
IconSuccess.defaultProps = {
  className: ''
};
IconSuccess.propTypes = {
  className: PropTypes.string
};
