import React from 'react';
import PropTypes from 'prop-types';
export default function IconUser({ className }){
  return(
    <svg className={className} fill="none" height="20" viewBox="0 0 16 20" width="16" xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d="M7.98779 10C10.7492 10 12.9878 7.76142 12.9878 5C12.9878 2.23858 10.7492 0 7.98779 0C5.22637 0 2.98779 2.23858 2.98779 5C2.98779 7.76142 5.22637 10 7.98779 10ZM7 12.5C7 12.0134 7.9472 12.0004 7.99789 12L8 12H8.15018C8.15019 12 8.1502 12 8.15022 12C8.42937 12.01 9 12.0815 9 12.5C9 12.7947 8.85847 12.8769 8.72601 12.9539C8.6095 13.0216 8.5 13.0852 8.5 13.2857C8.5 13.7143 9 15.5 9 15.5L10.9737 12.0461C12.7059 12.2828 14.2097 13.4152 14.9021 15.0565L15.8159 17.2226C16.3722 18.541 15.4042 20 13.9732 20H2.00211C0.571154 20 -0.39683 18.541 0.159384 17.2226L1.0732 15.0565C1.76867 13.408 3.28267 12.2729 5.02457 12.043L7 15.5C7 15.5 7.5 14 7.5 13.5C7.5 13.25 7.375 13.125 7.25 13C7.125 12.875 7 12.75 7 12.5Z" fillRule="evenodd"/>
    </svg>
  );
}
IconUser.defaultProps = {
  className: ''
};
IconUser.propTypes = {
  className: PropTypes.string
};
