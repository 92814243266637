import { FAILED, LOADING, SUCCESS, actionTypes } from './constants';

export const initialState = {
  dataAudit : {
    data: [],
    meta: {
      page: 0,
      totalPage: 0,
      totalData: 0,
    },
  },
  filterAudit: {},
  isLoadingAudit: false,
  messageAudit : '',
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key, filter } = action;
  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case actionTypes.FILTER:
      return {
        ...state,
        [`${key}`]: filter,
      };
    default:
      return state;
  }
}
