/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconAturHET({ className }){
  return(
    <svg className={className} fill="white" height="16" viewBox="0 0 20 16" width="20" xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d="M2 0C0.895432 0 0 0.895416 0 2L0 14C0 15.1046 0.895432 16 2 16L18 16C19.1046 16 20 15.1046 20 14V2C20 0.895416 19.1046 0 18 0L2 0ZM2 1C1.44772 1 1 1.44772 1 2L1 14C1 14.5523 1.44772 15 2 15L18 15C18.5523 15 19 14.5523 19 14V2C19 1.44772 18.5523 1 18 1L2 1ZM2 3C2 2.44772 2.44772 2 3 2L17 2C17.5523 2 18 2.44772 18 3V13C18 13.5523 17.5523 14 17 14L3 14C2.44772 14 2 13.5523 2 13L2 3ZM10 5.5C10 5.83774 9.88839 6.14938 9.70004 6.40009L10.9581 8.91626L11.0365 9.073C11.1824 9.0256 11.3382 9 11.5 9C11.6779 9 11.8485 9.03094 12.0068 9.08777L12.0884 8.9408L13.4211 6.54208C13.1604 6.27222 13 5.90485 13 5.5C13 4.67157 13.6716 4 14.5 4C15.3284 4 16 4.67157 16 5.5C16 6.32843 15.3284 7 14.5 7C14.3967 7 14.2958 6.98956 14.1984 6.96967L12.7362 9.65021C12.9026 9.89175 13 10.1845 13 10.5C13 11.3284 12.3284 12 11.5 12C10.6716 12 10 11.3284 10 10.5C10 10.1623 10.1116 9.85062 10.3 9.59991L9.03009 7.06018L8.96351 6.927C8.81754 6.97437 8.66176 7 8.5 7C8.30966 7 8.12759 6.96454 7.96006 6.89987L7.89016 7.01639L6.48091 9.36514C6.79884 9.6402 7 10.0466 7 10.5C7 11.3284 6.32843 12 5.5 12C4.67157 12 4 11.3284 4 10.5C4 9.67157 4.67157 9 5.5 9C5.53296 9 5.56567 9.00104 5.59811 9.00314L7.22533 6.29111C7.0825 6.06146 7 5.79034 7 5.5C7 4.67157 7.67157 4 8.5 4C9.32843 4 10 4.67157 10 5.5Z" fillRule="evenodd"/>
    </svg>
  );
}
IconAturHET.defaultProps = {
  className: '',
  color: '#25282B'
};
IconAturHET.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
