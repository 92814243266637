import React from 'react';
import PropTypes from 'prop-types';
export default function IconAktivasiManual({ className }){
  return(
    <svg className={className} fill="none" height="16" viewBox="0 0 23 16" width="23" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 15.5L15.75 15.5C16.4375 15.5 17 14.9375 17 14.25C17 13.5625 16.4375 13 15.75 13L2 13C1.3125 13 0.75 13.5625 0.75 14.25C0.75 14.9375 1.3125 15.5 2 15.5ZM2 9.25L12 9.25C12.6875 9.25 13.25 8.6875 13.25 8C13.25 7.3125 12.6875 6.75 12 6.75L2 6.75C1.3125 6.75 0.75 7.3125 0.75 8C0.75 8.6875 1.3125 9.25 2 9.25ZM0.75 1.75C0.75 2.4375 1.3125 3 2 3L15.75 3C16.4375 3 17 2.4375 17 1.75C17 1.0625 16.4375 0.5 15.75 0.5L2 0.5C1.3125 0.5 0.75 1.0625 0.75 1.75ZM22.375 11.6L18.775 8L22.375 4.4C22.8625 3.9125 22.8625 3.125 22.375 2.6375C21.8875 2.15 21.1 2.15 20.6125 2.6375L16.125 7.125C15.6375 7.6125 15.6375 8.4 16.125 8.8875L20.6125 13.375C21.1 13.8625 21.8875 13.8625 22.375 13.375C22.85 12.8875 22.8625 12.0875 22.375 11.6Z" fillRule="evenodd"/>
    </svg>
  );
}
IconAktivasiManual.defaultProps = {
  className: ''
};
IconAktivasiManual.propTypes = {
  className: PropTypes.string
};
