import { FAILED, LOADING, SUCCESS, SET_DATA } from './constants';

export const initialDownloadState = {
  generating: false,
  link: null,
};

export const initialState = {
  dataMerchantRegistrations: {
    data: [],
    meta: {
      scrollId: '',
      page: 0,
      totalPage: 0,
      totalData: 0,
      loadedAt: new Date(),
      lastDataAt: new Date(),
    },
  },
  dataFailedMerchantRegistrations: {
    data: [],
    meta: {
      scrollId: '',
      page: 0,
      totalPage: 0,
      totalData: 0,
      loadedAt: new Date(),
      lastDataAt: new Date(),
    },
  },
  isLoadingMerchantRegistrations: false,
  isLoadingFailedMerchantRegistrations: false,
  messageMerchantRegistrations: '',
  messageFailedMerchantRegistrations: '',
  dataMerchantRegistrationDetail: {},
  dataDownloadRegistrationReport: { ...initialDownloadState },
  isLoadingMerchantRegistrationDetail: false,
  messageMerchantRegistrationDetail: '',
  dataApproveRegistration: '',
  isLoadingApproveRegistration: false,
  isLoadingDownloadRegistrationReport: false,
  messageApproveRegistration: '',
  dataFilter: {},
  dataFailedFilter: {}
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    case SET_DATA:
      return {
        ...state,
        [`data${key}`]: {
          data: action.scrollId
            ? [...state.dataMerchantRegistrations.data, ...data.data]
            : data.data,
          meta: {
            ...data.meta,
            loadedAt: action.scrollId
              ? state.dataMerchantRegistrations.meta.loadedAt
              : new Date(),
            page: action.scrollId
              ? state.dataMerchantRegistrations.meta.page + 1
              : 1,
          },
        },
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    default:
      return state;
  }
}
