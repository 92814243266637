/* eslint-disable max-lines */
import fetch from './fetch';

const LOGIN = '/mypertamina/v1/admin/login';
const FORGOT_PASSWORD = '/mypertamina/v1/admin/reset-password';
const RESET_PASSWORD = '/password';
const MERCHANT_TYPES = '/merchantapp/v1/merchant-type';
const REGISTRATION_CSV_BULK = '/mypertamina/v1/users/bulk';
const UPDATE_PRICE_CSV_BULK = '/mypertamina/v1/admin/price-list/bulk';
const LIST_CATEGORY_FAQ = '/faqs/v1/myptm/faq/categories';
const LIST_FAQ = '/faqs/v1/myptm/faq/controlling';
const DETAIL_FAQ = `/faqs/v1/myptm/`;
const USER_PERMISSION_ENDPOINT = '/mypertamina/v1/admin/permition';
const USER_ROLE_ENDPOINT = '/mypertamina/v1/admin/role';
const USER = '/mypertamina/v1/admin/user';
const USER_V2 = '/mypertamina/v2/admin/user';
const LIST_BANK = '/mypertamina/v1/banks';
const SYNC_STOCK_MERCHANT = '/mypertamina/v1/simelon/sync/file';

export const basicAuthRoutes = [
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  MERCHANT_TYPES,
  // REGISTRATION_CSV_BULK,
  LIST_CATEGORY_FAQ,
  LIST_FAQ,
  DETAIL_FAQ,
  LIST_BANK,
];

export const loginUser = async (data) => await fetch.post(LOGIN, data);

export const logoutUser = async () =>
  await fetch.post('/mypertamina/v1/admin/logout');

export const summaryTransaction = async (startDate, endDate) =>
  await fetch.get('/mypertamina/v2/admin/summary-report', {
    params: { startDate, endDate },
  });

export const productStock = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/stock-report', {
    params,
  });

export const summaryTransactionDetails = async (
  startDate,
  endDate,
  categoryId = '',
) =>
  await fetch.get('/mypertamina/v2/admin/product-report', {
    params: { categoryId, startDate, endDate },
  });

export const templateHet = async (region = '', provinceId = '', cityId = '') =>
  await fetch.get('/mypertamina/v1/admin/price-list/template', {
    params: { region, provinceId, cityId },
  });

export const listSbm = async (params) =>
  await fetch.get('mypertamina/v1/admin/sbm', { params });

export const listAgents = async (search = '') =>
  await fetch.get('mypertamina/v1/admin/agens', { params: { search } });

export const listRegionals = async () =>
  await fetch.get('mypertamina/v1/admin/regionals');

export const listProvinces = async (params) =>
  await fetch.get('mypertamina/v1/admin/provinces', { params });

export const listCities = async (params) =>
  await fetch.get('/mypertamina/v1/admin/cities', { params });

export const listDistricts = async (params) =>
  await fetch.get('/mypertamina/v1/admin/districts', { params });

export const listVillages = async (params) =>
  await fetch.get('/mypertamina/v1/admin/villages', { params });

export const listSam = async (params) =>
  await fetch.get('/mypertamina/v1/admin/sam', { params });

export const listProductsCategories = async () =>
  await fetch.get('/mypertamina/v1/admin/categories');

export const listProducts = async (categoryId) => {
  return await fetch.get(`/products/v1/myptm/admin/${categoryId}/products`);
};

export const getTermCondition = async () =>
  await fetch.get(`/mypertamina/v1/admin/tnc`);

export const updateTermCondition = async (params) =>
  await fetch.put(`/mypertamina/v1/admin/tnc`, params);

export const listCategoryFaq = async (params) =>
  await fetch.get(LIST_CATEGORY_FAQ, { params });

export const detailFaq = async (id) =>
  await fetch.get(`${DETAIL_FAQ}${id}/faq`);

export const deleteFaq = async (id) =>
  await fetch.delete(`${DETAIL_FAQ}${id}/faq`);

export const uploadImageFaq = async (file) =>
  await fetch.post(`/faqs/v1/myptm/image-faq`, file);

export const listFaq = async (params) => await fetch.get(LIST_FAQ, { params });

export const chartReport = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/chart-report', { params });

export const dataOutlet = async (params = {}) =>
  await fetch.get('/mypertamina/v2/admin/outlets', { params });

export const outletTransactions = async (params = {}) =>
  await fetch.get('/mypertamina/v2/admin/transaction-agent-report', { params });

export const getUpdateStockMerchant = async (params = {}) =>
  await fetch.get(SYNC_STOCK_MERCHANT, { params });

export const syncStockMerchant = async (historyId) =>
  await fetch.get(`${SYNC_STOCK_MERCHANT}/${historyId}`);

export const postUploadFileSyncStockMerchant = async (payload) =>
  await fetch.post(SYNC_STOCK_MERCHANT, payload);

export const requestGenerateOutletReport = async (params = {}) =>
  await fetch.get('/mypertamina/v2/admin/download-outlets', {
    params,
  });

export const checkGeneratedOutletReport = async (cancelToken, params = {},) => {
  return fetch.get('/mypertamina/v2/admin/download-outlets/get-file-excel', {
    params,
    cancelToken,
  });
};

export const requestGenerateRegistrationReport = async (params = {}) =>
  await fetch.get('/mypertamina/v2/admin/registration/merchant-list-download', {
    params,
  });

export const checkGeneratedRegistrationReport = async (
  cancelToken,
  params = {},
) => {
  return fetch.get(
    '/mypertamina/v2/admin/registration/merchant-list-download/get-file-excel',
    {
      params,
      cancelToken,
    }
  );
};

export const outletTransactionReport = async (outletId = '', date = '') =>
  await fetch.get('/mypertamina/v1/admin/download-transaction-agent', {
    params: { outletId, date },
  });

export const detailOutlet = async (outletId = '') =>
  await fetch.get(`/mypertamina/v1/admin/${outletId}/outlet`);

export const salesReportChart = async (params = {}) => {
  const { categoryId } = params;

  let url = '/mypertamina/v1/admin/selling-report';
  if (categoryId === 'non-pertamina') {
    url = '/mypertamina/v1/admin/selling-report-nonpertamina/chart';
    delete params.categoryId;
  }
  return await fetch.get(url, {
    params,
  });
};

export const salesReportDetail = async (params = {}) => {
  const { categoryId } = params;
  let url = '/mypertamina/v2/admin/selling-report-detail';

  if (categoryId === 'non-pertamina') {
    url = '/mypertamina/v1/admin/selling-report-nonpertamina';
    delete params.categoryId;
  }

  return await fetch.get(url, {
    params,
  });
};

export const downloadSalesReport = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/download-selling-report', {
    params,
  });

export const requestGenerateSalesReport = async (
  type = 'excel',
  params = {}
) => {
  const { categoryId } = params;

  let url =
    type === 'excel'
      ? '/mypertamina/v2/admin/download-selling-report-excel'
      : '/mypertamina/v2/admin/download-selling-report-pdf';
  if (categoryId === 'non-pertamina') {
    url =
      type === 'excel'
        ? '/mypertamina/v1/admin/selling-report-nonpertamina/generate-excel'
        : '/mypertamina/v1/admin/selling-report-nonpertamina/generate-pdf';
    delete params.categoryId;
  }

  return fetch.get(url, {
    params,
  });
};

export const checkGeneratedSalesReport = async (
  cancelToken,
  type = 'excel',
  params = {}
) => {
  const { categoryId } = params;
  let url =
    type === 'excel'
      ? '/mypertamina/v2/admin/selling-report/get-file-excel'
      : '/mypertamina/v2/admin/selling-report/get-file-pdf';
  if (categoryId === 'non-pertamina') {
    url =
      type === 'excel'
        ? '/mypertamina/v1/admin/selling-report-nonpertamina/get-excel'
        : '/mypertamina/v1/admin/selling-report-nonpertamina/get-pdf';
    delete params.categoryId;
  }

  return fetch.get(url, {
    params,
    cancelToken,
  });
};

export const paymentMethodReport = async (range = 'week') =>
  await fetch.get('/mypertamina/v2/admin/payment-method-report', {
    params: { range },
  });

export const bestSelling = async (startDate, endDate) =>
  await fetch.get('/mypertamina/v1/admin/best-selling', {
    params: { startDate, endDate },
  });

export const productPrices = async (params = { categoryId: '' }) =>
  await fetch.get('/mypertamina/v1/admin/price-list', { params });

export const productPriceByCity = async (categoryId = '', cityId = '') =>
  await fetch.get('/mypertamina/v1/admin/city-price-list', {
    params: { categoryId, cityId },
  });

export const updateProductPrices = async (
  data = { cityId: '', products: [] }
) => await fetch.put('/mypertamina/v1/admin/price-list', data);

export const forgotPassword = async (email) =>
  await fetch.get(`${FORGOT_PASSWORD}/${email}`);

export const resetPassword = async (data) =>
  await fetch.put(`/mypertamina/v1/admin/password`, data);

export const merchantTypes = async () => await fetch.get(MERCHANT_TYPES);

export const getDetailAdmin = async () =>
  await fetch.get(`/mypertamina/v1/admin/profile`);

export const getCustomerByTransaction = async (transactionId) =>
  await fetch.get('/mypertamina/v1/transaction/get-customer', {
    params: { transactionId },
  });

export const merchantRegistrations = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/merchants/inject-success', {
    params,
  });

export const failedmerchantRegistrations = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/merchants/inject-failed', {
    params,
  });

export const auditlog = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/audit-logs', {
    params,
  });

export const downloadAudit = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/audit-logs/download', {
    params,
  });

export const failedMerchantRegistrationDetail = async (registrationId) =>
  await fetch.get(
    `/mypertamina/v1/admin/merchants/inject-failed/${registrationId}`
  );

export const downloadFailedRegistration = async (params) =>
  await fetch.get(`/mypertamina/v1/admin/merchants/inject-failed/download`, {
    params,
  });

export const merchantRegistrationDetail = async (registrationId) =>
  await fetch.get(
    `/mypertamina/v1/admin/merchants/inject-success/${registrationId}`
  );

export const syncMerchantMonica = async (id) =>
  await fetch.get(`/mypertamina/v1/admin/merchants/${id}/sync`);

export const agenHistory = async (userId, params) =>
  await fetch.get(
    `/mypertamina/v1/admin/merchants/${userId}/agen`,{
      params
    }
  );

export const approveMerchantRegistration = async (registrationId) =>
  await fetch.post(
    `/mypertamina/v1/admin/merchant-registrations/${registrationId}/approve`
  );

export const rejectMerchantRegistration = async (registrationId, reason) =>
  await fetch.post(
    `mypertamina/v1/admin/merchant-registrations/${registrationId}/reject`,
    {
      reason,
    }
  );

export const revitionMerchantRegistration = async (registrationId, reason) =>
  await fetch.post(
    `mypertamina/v1/admin/merchant-registrations/${registrationId}/need-revision`,
    {
      reason,
    }
  );

export const processVerification = async (params = {}) =>
  await fetch.post(
    '/mypertamina/v1/admin/merchant-registrations/process',
    params
  );

export const saveFaq = async (params = {}) =>
  await fetch.post('/faqs/v1/myptm/faq', params);

export const updateFaq = async (id, params = {}) =>
  await fetch.put(`/faqs/v1/myptm/${id}/faq`, params);

export const registrationMerchantCSVBulk = async (file) =>
  await fetch.post(REGISTRATION_CSV_BULK, file);

export const uploadBulkPrice = async (file) =>
  await fetch.post(UPDATE_PRICE_CSV_BULK, file);

export const manualLoggingActivation = async (params = {}) =>
  await fetch.get('/mypertamina/v1/admin/transaction/manual', { params });
export const settingManualActivation = async (params) =>
  await fetch.get('/mypertamina/v1/admin/automatic-activations', { params });

export const downloadManualLogging = async (params) =>
  await fetch.get('/mypertamina/v1/admin/transaction/manual/download', {
    params,
  });

export const manualLoggingSubmissionProcess = async (payload) =>
  await fetch.post('/mypertamina/v1/admin/transaction/manual/process', payload);

export const manualLoggingSubmissionApprove = async (payload) =>
  await fetch.post('/mypertamina/v1/admin/transaction/manual/approve', payload);

export const manualLoggingSubmissionReject = async (payload) =>
  await fetch.post('/mypertamina/v1/admin/transaction/manual/reject', payload);

export const postAutoApprove = async (payload) =>
  await fetch.post('/mypertamina/v1/admin/automatic-activations', payload);

export const updateStatusManualLoggingActivation = async (
  params = {},
  id = ''
) =>
  await fetch.put(
    `/mypertamina/v1/admin/manual-activation/${id}?status=${params.status}`
  );

export const requestGenerateManualLoggingActivationReport = async (
  params = {}
) =>
  await fetch.get('/mypertamina/v1/admin/download-manual-activation', {
    params,
  });

export const checkGeneratedManualLoggingActivationReport = async (
  cancelToken,
  params = {},
) => {
  return fetch.get(
    '/mypertamina/v1/admin/download-manual-activation/get-file-excel',
    {
      params,
      cancelToken,
    }
  );
};

export const listPermissionUser = async (params) =>
  await fetch.get(USER_PERMISSION_ENDPOINT, { params });

export const userPermissionPost = async (payload) => {
  const modPayload = {
    name: payload.permission,
    path: payload.path,
  };
  return await fetch.post(USER_PERMISSION_ENDPOINT, modPayload);
};

export const userPermissionPut = async (payload) => {
  const modPayload = {
    name: payload.permission,
    path: payload.path,
  };

  return await fetch.put(
    `${USER_PERMISSION_ENDPOINT}/${payload.permissionId}`,
    modPayload
  );
};

export const merchantRegistrationList = async (params) => {
  return await fetch.get('/mypertamina/v1/admin/merchant-registrations', {
    params,
  });
};

export const settingMerchantList = async (params) => {
  return await fetch.get('/mypertamina/v1/admin/merchants', { params });
};

export const updateAgen = async (id, payload) => {
  return await fetch.put(
    `/mypertamina/v1/admin/merchants/${id}/agen`,
    payload
  );
};

export const getNotificationDraft = async (params) => {
  return await fetch.get('/notifications/v1/map-notifications', {
    params,
  });
};

export const getLogsSyncMerchant = async (params) => {
  return await fetch.get('/mypertamina/v1/admin/merchants/sync-log', { params });
};

export const getLogsSyncMerchantDetail = async (id) => {
  return await fetch.get(`/mypertamina/v1/admin/merchants/sync-log/${id}`);
};

export const getDownloadLogs = async (params) => {
  return await fetch.get('/mypertamina/v1/admin/merchants/sync-log/download', { params });
};

export const getNotificationActive = async () => {
  return await fetch.get('/notifications/v1/map-notifications/active');
};

export const getDetailNotification = async (id) => {
  return await fetch.get(`/notifications/v1/map-notifications/${id}`);
};

export const postOrderingNotification = async (payload) => {
  return await fetch.post('/notifications/v1/map-notifications/active',  payload );
};

export const notificationDelete = async (id) => {
  return await fetch.delete(`/notifications/v1/map-notifications/${id}` );
};

export const postNotification = async (type, payload) => {
  return await fetch.post(`/notifications/v1/map-notifications/${type}`, payload);
};

export const putNotification = async (id, type, payload) => {
  return await fetch.put(`/notifications/v1/map-notifications/${type}/${id}`, payload);
};

export const postNotificationImage = async (payload) => {
  return await fetch.post('/notifications/v1/map-notifications/image', payload);
};

export const postNotificationText = async (payload) => {
  return await fetch.post('/notifications/v1/map-notifications/text', payload);
};

export const suspendMerchant = async (id, payload) => {
  return await fetch.post(
    `/mypertamina/v1/admin/merchants/suspend/${id}`,
    payload
  );
};

export const unsuspendMerchant = async (id, payload) => {
  return await fetch.put(
    `/mypertamina/v1/admin/merchants/unsuspend/${id}`,
    payload
  );
};

export const identityImage = async (id, payload) => {
  return await fetch.put(`/mypertamina/v1/admin/merchants/${id}/update-identity`, payload);
};

export const editMerchant = async (id, payload) => {
  return await fetch.put(`/mypertamina/v1/admin/merchants/${id}`, payload);
};

export const resendMerchant = async (id) => {
  return await fetch.get(`/mypertamina/v1/admin/merchants/resend/${id}`);
};

export const transactionManualHistory = async (userId, params) => {
  return await fetch.get(
    `/mypertamina/v1/admin/transaction/manual/histories/${userId}`,
    { params }
  );
};

export const deactivateTransactionManual = async (payload) => {
  return await fetch.post(
    '/mypertamina/v1/admin/transaction/manual/deactivate',
    payload
  );
};

export const validateBank = async (params) => {
  return await fetch.get('/mypertamina/v1/bank/validate', { params });
};

export const listBank = async (params) => {
  return await fetch.get(LIST_BANK, { params });
};

export const myptmActivationList = async (params) => {
  return await fetch.get('/mypertamina/v1/admin/myptm-activation', { params });
};

export const myptmActivationLinkAjaDownload = async (params) => {
  return await fetch.get('/mypertamina/v1/admin/myptm-activation/process/download', {
    params,
  });
};

export const myptmActivationFormatDownload = async (params) => {
  return await fetch.get('/mypertamina/v1/admin/myptm-activation/download', {
    params,
  });
};

export const myptmActivationProcess = async (file) => {
  return await fetch.post(
    '/mypertamina/v1/admin/myptm-activation/process',
    file
  );
};

export const myptmActivationManual = async (payload) => {
  return await fetch.post(
    '/mypertamina/v1/admin/myptm-activation/activate',
    payload
  );
};

export const myptmActivationFile = async (payload) => {
  return await fetch.post(
    '/mypertamina/v1/admin/myptm-activation/activate/excel',
    payload,
  );
};

export const listRoleUser = async (params) => {
  return await fetch.get(USER_ROLE_ENDPOINT, { params });
};

export const userRolePost = async (payload) => {
  return await fetch.post(USER_ROLE_ENDPOINT, payload);
};
export const userRolePut = async (payload) => {
  return await fetch.put(`${USER_ROLE_ENDPOINT}/${payload.roleId}`, payload);
};

export const userList = async (params) => {
  return await fetch.get(USER_V2, { params });
};

export const createUser = async (payload) => {
  return await fetch.post(USER_V2, payload);
};

export const updateUser = async (id, payload) => {
  return await fetch.put(`${USER_V2}/${id}`, payload);
};

export const updateUserPassword = async (id, payload) => {
  return await fetch.put(`${USER}/password/${id}`, payload, { isBearer: true });
};
