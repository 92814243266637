import React from 'react';
import PropTypes from 'prop-types';
export default function IconActive({ className }){
  return(
    <svg className={className} fill="none" height="10" viewBox="0 0 13 10" width="13" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0458 0.485595C12.3299 0.787026 12.3158 1.26169 12.0144 1.54579L4.08456 9.01968C3.74829 9.33662 3.22042 9.32686 2.89609 8.9977L0.215757 6.27737C-0.074959 5.98231 -0.0714425 5.50745 0.223612 5.21674C0.518666 4.92602 0.993526 4.92954 1.28424 5.22459L3.51839 7.49207L10.9856 0.454214C11.287 0.170114 11.7617 0.184164 12.0458 0.485595Z" fill="#44AB4A"/>
    </svg>

  );
}
IconActive.defaultProps = {
  className: ''
};
IconActive.propTypes = {
  className: PropTypes.string
};
