import { CHANGED, LOADING, FAILED, MESSAGE } from './constants';

export const initialState = {
  isLoading: false,
  message: '',
  success: false,
  code: 200,
};

export default function reducer(state = initialState, action) {
  const { type, isLoading, message, success, code } = action;

  switch (type) {
    case CHANGED:
      return {
        ...state,
        isLoading: false,
        message,
        success,
      };
    case FAILED:
      return {
        ...state,
        message,
        code,
      };

    case LOADING:
      return {
        ...state,
        isLoading,
      };
    case MESSAGE:
      return {
        ...state,
        code,
        message,
      };
    default:
      return state;
  }
}
