import React from 'react';
import { Modal } from '@mantine/core';
import styles from './styles.scoped.css';
import Button from '../../../elements/Button';
import PropTypes from 'prop-types';


export default function ModalLogout({ open, onClose, onClick }) {
  return (
    <Modal centered className={styles.modalLogout}
      onClose={onClose}
      opened={open}
      padding="lg"
      size="auto"
      withCloseButton={false}>
      <h3>Apakah Anda yakin ingin keluar?</h3>
      <div className={styles.actionContainerLogout}>
        <Button onClick={onClose} outlined>
            Batal
        </Button>
        <Button onClick={onClick} style={'blue'}>
            Keluar
        </Button>
      </div>
    </Modal>
  );
}

ModalLogout.defaultProps = {
  onClick: () => {},
  onClose: () => {},
  open: false,
};

ModalLogout.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
