import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scoped.css';
import { MultiSelect } from '@mantine/core';
import { itemComponent } from './extendElement';
import { jsonParser } from '../../../utils/builder/common';
export default function MultipleSelect(props) {
  const {
    className,
    hideLabel,
    input,
    eventName,
    inputProps,
    disabled,
    data,
    name,
    placeholder,
    rightSection,
    defaultValue,
    border,
    searchable,
    onChange,
    overflow,
    debounce,
    onSearchChange,
    value,
    isUseStyle,
    required,
    showErrorWithoutTouched,
    meta: { touched, error },
  } = props;
  const label = input.value && !hideLabel;
  const notEmpty = label ? styles['not-empty'] : '';
  const isError = touched && error ? styles.isError : '';
  const [errors, setErrors] = useState();
  const [searchValue, setSearchValue] = useState('');
  const classes = [styles.root, className, notEmpty, isError, border ? styles.border : '']
    .filter(Boolean)
    .join(' ');
  useEffect(() => {
    if(touched && error){
      setErrors(error);
    }
  }, [touched]);

  const handleSearch = (value) => {
    if(debounce){
      setSearchValue(value);
    }else{
      onSearchChange(value, eventName);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearchChange(searchValue, eventName);
    }, 500); // Debounce delay of 500ms

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  return (
    <div>
      <MultiSelect
        {...inputProps}
        className={isUseStyle && classes}
        data={data}
        defaultValue={defaultValue || []}
        disabled={disabled}
        error={errors}
        itemComponent={itemComponent}
        name={name}
        onChange={(e) => {
          onChange(e);
          input.onChange && input.onChange(e);
          setErrors('');
        }}
        onSearchChange={handleSearch}
        placeholder={placeholder}
        rightSection={rightSection}
        searchable={searchable}
        styles={{
          wrapper: {
            overflow: `${overflow} !important`,
          },
          values: { width: '90%' },
          label: { fontWeight: '600', fontSize: '1rem' }
        }}
        value={jsonParser(value).length > 0 ?  jsonParser(value) : value || []}
        withAsterisk={required}
      />
      {touched && error && <span className={styles.error}>{error}</span>}
      {error && showErrorWithoutTouched && (
        <span className={styles.error}>{error}</span>
      )}
    </div>
  );
}

MultipleSelect.defaultProps = {
  border: true,
  className: '',
  data: [],
  debounce: false,
  defaultValue: [],
  disabled: false,
  eventName: '',
  hideLabel: false,
  input: {},
  inputProps: {},
  isUseStyle: true,
  meta: {},
  name: '',
  onChange: () => {},
  onSearchChange: () => {},
  overflow: 'hidden',
  placeholder: '',
  required: false,
  rightSection: '',
  searchable: false,
  showErrorWithoutTouched: true,
  value: [],
};

MultipleSelect.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.array,
  debounce: PropTypes.bool,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
  eventName: PropTypes.string,
  hideLabel: PropTypes.bool,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  isUseStyle: PropTypes.object,
  meta: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  overflow: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rightSection: PropTypes.node,
  searchable: PropTypes.bool,
  showErrorWithoutTouched: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
};
