export const jsonParser = (data) => {
  let initData = data || '';
  try {
    initData = JSON.parse(initData);
  } catch (error) {
    initData = [];
  }
  return initData || [];
};
export const removeDuplicateDataAob = (data, identifier)=> {
  const uniqueSet = new Set();
  const uniqueObjects = [];

  for (const obj of data) {
    if (!uniqueSet.has(obj[identifier])) {
      uniqueSet.add(obj[identifier]);
      uniqueObjects.push(obj);
    }
  }
  return uniqueObjects;
};

export const handleValueSelected = (firstValue, secondValue) => {
  if(firstValue){
    return firstValue;
  }else if(secondValue){
    return [secondValue];
  }else{
    return '';
  }
};
