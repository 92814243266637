import React from 'react';
import PropTypes from 'prop-types';

function IconUpDownArrow({ className, style, type }) {
  return (
    type === 'small' ?
      <svg fill="none" height="10" viewBox="0 0 8 12" width="7" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.30833 7.195L3.72167 9.78167L1.135 7.195C0.875 6.935 0.455 6.935 0.195 7.195C-0.065 7.455 -0.065 7.875 0.195 8.135L3.255 11.195C3.515 11.455 3.935 11.455 4.195 11.195L7.255 8.135C7.515 7.875 7.515 7.455 7.255 7.195C6.995 6.94167 6.56833 6.935 6.30833 7.195V7.195Z" fill="#868E96"/>
        <path d="M1.14162 4.195L3.72828 1.60833L6.31495 4.195C6.57495 4.455 6.99495 4.455 7.25495 4.195C7.51495 3.935 7.51495 3.515 7.25495 3.255L4.19495 0.194999C3.93495 -0.0650007 3.51495 -0.0650007 3.25495 0.194999L0.194953 3.255C-0.0650475 3.515 -0.0650475 3.935 0.194952 4.195C0.454952 4.44833 0.881618 4.455 1.14162 4.195V4.195Z" fill="#868E96"/>
      </svg>
      :
      <svg
        className={className}
        fill="none"
        height="32"
        style={style}
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.9999 7.77297L19.2799 11.053C19.7999 11.573 20.6399 11.573 21.1599 11.053C21.6799 10.533 21.6799 9.69297 21.1599 9.17297L16.9332 4.93297C16.4132 4.41297 15.5732 4.41297 15.0532 4.93297L10.8265 9.17297C10.3065 9.69297 10.3065 10.533 10.8265 11.053C11.3465 11.573 12.1865 11.573 12.7065 11.053L15.9999 7.77297ZM15.9999 24.2263L12.7199 20.9463C12.1999 20.4263 11.3599 20.4263 10.8399 20.9463C10.3199 21.4663 10.3199 22.3063 10.8399 22.8263L15.0665 27.0663C15.5865 27.5863 16.4265 27.5863 16.9465 27.0663L21.1732 22.8396C21.6932 22.3196 21.6932 21.4796 21.1732 20.9596C20.6532 20.4396 19.8132 20.4396 19.2932 20.9596L15.9999 24.2263Z" />
      </svg>
  );
}

IconUpDownArrow.defaultProps = {
  className: '',
  style: { fill: '#323232' },
  type: 'large'
};

IconUpDownArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string
};

export default IconUpDownArrow;
