export const FAILED = 'Penjualan/failed';
export const LOADING = 'Penjualan/loading';
export const SUCCESS = 'Penjualan/success';

export const DATA = [
  {
    type: 'Cash',
    total: 20028320,
    color: '#E6292B',
  },
  {
    type: 'My Pertamina / LinkAja',
    total: 5418368,
    color: '#2F80ED',
  },
];

export const META = {
  page: 1,
  totalPage: 1,
  count: 2,
};

export const ranges = [
  { key: 'week', text: 'Minggu Ini' },
  { key: 'month', text: 'Bulan Ini' },
  { key: 'year', text: 'Tahun Ini' },
];
