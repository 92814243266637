import { DatePickerInput } from '@mantine/dates';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scoped.css';

export default function DateRangePicker({
  inputProps,
  defaultValue,
  minDate,
  maxDate,
  onChange,
  value
}){

  return(
    <DatePickerInput
      {...inputProps}
      defaultValue={defaultValue}
      label={inputProps.label}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      styles={{ label: { fontWeight: '600', fontSize: '1rem' } }}
      type="range"
      value={value}
      withAsterisk
      zIndex={1000}
    />
  );
}

DateRangePicker.defaultProps = {
  defaultValue: [],
  input: {},
  inputProps: {},
  label: '',
  maxDate: '',
  meta: {},
  minDate: '',
  onChange: () => {},
  value: []
};

DateRangePicker.propTypes = {
  defaultValue: PropTypes.array,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  maxDate: PropTypes.date,
  meta: PropTypes.object,
  minDate: PropTypes.date,
  onChange: PropTypes.func,
  value: PropTypes.array
};

