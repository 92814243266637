/* eslint-disable no-nested-ternary */
import { Burger, Header, MediaQuery } from '@mantine/core';
import React from 'react';
import styles from './styles.scoped.css';
import { analytics } from '../../../../utils/firebase';
import IconLogout from '../../../icons/IconLogout';
import Banner from '../../../elements/Banner';
import IconArrowDown from '../../../icons/IconArrowDown';
import MenuDropdown from '../../../elements/MenuDropdown';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { renderIconBreadcrumbs } from '../PageBase';
import IconSuccess from '../../../icons/IconSuccess';
import IconInfoRevition from '../../../icons/IconInfoRevition';
import IconFailed from '../../../icons/IconFailed';
import PropTypes from 'prop-types';

function HeaderLayout({ opened, theme, setOpened, dataDetailUser,
  bcs, toastr, pathname, setModal } ) {
  const matchesMobile = useMediaQuery('(max-width: 768px)');
  const listSettings = [
    {
      name: 'Log out',
      onClick: () => {
        analytics.logEvent('click_logout', {
          from_path: location.pathname,
        });
        setModal(true);
      },
      icon: <IconLogout />,
    },
  ];
  matchesMobile && listSettings.unshift({
    name: (
      <div className={styles.row}>
        <span>{dataDetailUser.email}</span>
        <small>{dataDetailUser.name}</small>
      </div>
    )
  },);

  const showNameBreadcrumb = (idx, value) => {
    if(matchesMobile && idx !== bcs.length - 1){
      return '...';
    }else if(value.name){
      return value.name;
    }else{
      return value;
    }
  };

  function IconToastr(type) {
    if (type === 'success') {
      return <IconSuccess className={styles.icon} />;
    } else if (type === 'info') {
      return <IconInfoRevition />;
    } else {
      return <IconFailed />;
    }
  }

  return (
    <Header className={styles.header} height={{ base: matchesMobile ? 100 : 110 }} hidden={opened} p="md">
      <div className={styles.topContainer}>
        <MediaQuery largerThan="sm" styles={{ display: 'none !important' }}>
          <Burger
            className={styles.menuIcon}
            color={theme?.colors?.dark[6]}
            mr="xl"
            onClick={() => setOpened((o) => !o)}
            opened={opened}
            size="md"
          />
        </MediaQuery>
        <div className={styles.profileContainer}>
          <MediaQuery smallerThan="md" styles={{ display: 'none !important' }}>
            <div className={styles.row}>
              <span>{dataDetailUser.email}</span>
              <small>{dataDetailUser.name}</small>
            </div>
          </MediaQuery>
          <MenuDropdown listDropDown={listSettings} position="bottom-end">
            <IconArrowDown />
          </MenuDropdown>
        </div>
      </div>
      <div className={styles.breadcrumbs}>
        <span>{renderIconBreadcrumbs()}</span>
        {bcs.reduce((acc, b, idx) => {
          if (idx + 1 === bcs.length) {
            return [
              ...acc,
              idx ? (
                <React.Fragment key={`s${idx}`}>&nbsp;</React.Fragment>
              ) : (
                ''
              ),
              <span key={idx}>{b}</span>,
            ];
          }
          return [
            ...acc,
            <Link key={idx} to={b?.to ? b.to : `/${pathname.split('/')?.[idx+1]}`}>
              {showNameBreadcrumb(idx, b)}&nbsp;
            </Link>,
            <React.Fragment key={`s${idx}`}>&nbsp;/&nbsp;</React.Fragment>,
          ];
        }, [])}

        {toastr?.visible ? (
          <div className={styles.alert}>
            <Banner
              {...toastr}
              icon={IconToastr(toastr.type)}
            />
          </div>
        ) : null}
      </div>
    </Header>
  );
}

export default HeaderLayout;

HeaderLayout.defaultProps={
  bcs: [],
  dataDetailUser: {},
  opened: false,
  pathname: '',
  setModal: () => {},
  setOpened: () => {},
  theme: {},
  toastr: {},
};

HeaderLayout.propTypes = {
  bcs: PropTypes.array,
  dataDetailUser: PropTypes.object,
  opened: PropTypes.object,
  pathname: PropTypes.string,
  setModal: PropTypes.func,
  setOpened: PropTypes.func,
  theme: PropTypes.object,
  toastr: PropTypes.bool,
};
