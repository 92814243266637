import { FAILED, LOADING, SUCCESS } from './constants';

export const initialState = {
  dataProducts: [],
  dataProductPrices: {
    products: [],
  },
  dataProductPriceLists: {
    data: [],
    meta: { page: 1, totalData: 0, totalPage: 0 },
  },
  dataFilter: {
    regionalId: '',
    provinceId: '',
    cityId: ''
  },
  dataSubmitProductPrices: false,
  dataUploadUpdatePrice : [],
  dataTemplateHet: { },
  isLoadingProductPrices: false,
  isLoadingProductPriceLists: false,
  isLoadingProducts: false,
  isLoadingSubmitProductPrices: false,
  isLoadingUploadUpdatePrice: false,
  isLoadingTempateHet: false,
  messageProductPrices: '',
  messageProductPriceLists: '',
  messageSubmitProductPrices: '',
  messageUploadUpdatePrice: '',
  messageTemplateHet: ''
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    default:
      return state;
  }
}
