import {
  FAILED,
  LOADING,
  MERCHANT_VERIFICATION_FAILED,
  MERCHANT_VERIFICATION_LOADING,
  MERCHANT_VERIFICATION_SET_DATA,
  MERCHANT_VERIFICATION_SET_FILTER,
  MERCHANT_VERIFICATION_SUCCESS,
  SUCCESS,
  IS_OVER_LIMIT_ROWS_SET_DATA,
} from './constants';
import { initialValues as filter } from '../../components/fragments/MerchantVerificationFilter/fields';


export const initialState = {
  data: [],
  meta: {
    page: 1,
    size: 100,
    totalPage: 1,
    totalData: 0,
    summary: []
  },
  loading: false,
  message: '',
  filter,
  dataVerificationRegistrations: {
    data: [],
    meta: {
      page: 0,
      totalPage: 0,
      totalData: 0,
    },
  },
  isLoadingVerificationRegistrations: false,
  messageVerificationRegistrations: '',
  dataMerchantRegistrationDetail: [],
  isLoadingMerchantRegistrationDetail: true,
  messageMerchantRegistrationDetail: '',
  dataSubmitApproved: '',
  isOverLimitRows: { showAlert: true, rowsSelected: 0 },
  isLoadingSubmitApproved: true,
  messageSubmitApproved: '',
  dataSubmitRejected: '',
  isLoadingSubmitRejected: true,
  messageSubmitRejected: '',
  dataSubmitRevition: '',
  isLoadingSubmitRevition: true,
  messageSubmitRevition: '',
};

export default function reducer(state = initialState, action) {
  const { type, data, loading, message, meta, initial, filter, key } = action;

  switch (type) {
    case MERCHANT_VERIFICATION_FAILED:
      return { ...state, data: initialState.data, loading: false, message };
    case MERCHANT_VERIFICATION_LOADING:
      return { ...state, loading, message: '' };
    case MERCHANT_VERIFICATION_SUCCESS:
      return { ...state, data, loading: false, message: '' };
    case MERCHANT_VERIFICATION_SET_DATA:
      return {
        ...state,
        data: initial ? data : [...state.data, ...data],
        meta,
        loading: false,
        message: '',
      };
    case IS_OVER_LIMIT_ROWS_SET_DATA:
      return{
        ...state,isOverLimitRows: data
      };
    case MERCHANT_VERIFICATION_SET_FILTER:
      return { ...state, filter };
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: loading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    default:
      return state;
  }
}
