import React from 'react';
import BadgeStatus from '../../components/elements/BadgeStatus';

export const FAILED = 'UpdateStock/failed';
export const LOADING = 'UpdateStock/loading';
export const SUCCESS = 'UpdateStock/success';

export const typestock =
  { 1: 'Penebusan',
    2: 'Stok Akhir',
    3: 'Referensi'
  };

export const statusFile = {
  1: <BadgeStatus label={'Berhasil Upload'} />,
  2: <BadgeStatus label={'Stok Sedang Disinkron'} />,
  3: <BadgeStatus label={'Stok Tersinkronisasi'} />,
  4: <BadgeStatus label={'Gagal'} />,
};
