import { CLEAR_MESSAGE, FAILED, LOADING, SET_DATA, SUCCESS } from './constants';

export const initialState = {
  data: [],
  meta: {
    scrollId: '',
    page: 1,
    size: 100,
    totalPage: 1,
    totalData: 0,
  },
  loading: false,
  message: '',
  success: false,
};

export default function reducer(state = initialState, action) {
  const { type, data, loading, message, meta, success, initial } = action;

  switch (type) {
    case FAILED:
      return { ...state, loading: false, success: false, message };
    case LOADING:
      return { ...state, loading, message: '', success: false };
    case SUCCESS:
      return { ...state, success, loading: false, message: '' };
    case SET_DATA:
      return {
        ...state,
        data: initial ? data : [...state.data, ...data],
        meta,
        loading: false,
        message: '',
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    default:
      return state;
  }
}
