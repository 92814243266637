import dayjs from 'dayjs';
import { actionTypes } from './constants';

export const initialState = {
  table: {
    data: [],
    meta: {},
    filter: {
      startDate: dayjs(dayjs().subtract(6, 'day').toDate()).format('YYYY-MM-DD'),
      endDate: dayjs(dayjs().toDate()).format('YYYY-MM-DD'),
    },
    loading: false,
    error: {},
    message: '',
    success: true,
  },
  detail: {
    data: {
      'registrationId': '',
      'date': '',
      'time': '',
      'storeName': '',
      'agen': '',
      'phoneNumber': '',
      'email': '',
      'mid': '',
      'reasons':[],
      'status': ''
    },
    loading: false,
    error: {},
    message: '',
    success: true,
  },
  download: {
    data: {},
    loading: false,
    error: {},
    message: '',
    success: true,
  }
};
export default function reducer(state = initialState, action){
  const {
    type,
    data,
    loading,
    message = '',
    success = true,
    meta,
    filter,
    key,
    error
  } = action;
  switch (type) {
    case actionTypes.FAILED:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: initialState[key].data,
          error: error,
          loading: false,
          message,
          success: false,
        },
      };
    case actionTypes.LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          success: false,
          loading,
          message: '',
        },
      };
    case actionTypes.SUCCESS:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: data,
          meta,
          loading: false,
          error: {},
          message,
          success,
        },
      };
    case actionTypes.FILTER:
      return {
        ...state,
        [key]: {
          ...state[key],
          filter,
        },
      };
    default:
      return state;
  }
}
