/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconVerification({ className }){
  return(
    <svg className={className} fill="none" height="16" viewBox="0 0 20 16" width="26" xmlns="http://www.w3.org/2000/svg">
      <path clipRule="evenodd" d="M2 0C0.895432 0 0 0.895432 0 2L0 14C0 15.1046 0.895432 16 2 16L18 16C19.1046 16 20 15.1046 20 14V2C20 0.895432 19.1046 0 18 0L2 0ZM2 4C2 3.44772 2.44772 3 3 3L6 3C6.55228 3 7 3.44772 7 4C7 4.55228 6.55228 5 6 5L3 5C2.44772 5 2 4.55228 2 4ZM17.6094 6.73774L15.0545 6.39659L13.9148 4.23881C13.8321 4.08529 13.6667 4 13.5013 4C13.3358 4 13.1796 4.07676 13.0877 4.23881L11.9481 6.38806L9.39308 6.72921C9.01627 6.78038 8.86922 7.20683 9.13574 7.45415L10.983 9.1258L10.5511 11.4968C10.4959 11.7697 10.7349 11.9915 11.0014 11.9915C11.075 11.9915 11.1485 11.9744 11.2128 11.9403L13.5013 10.8316L15.7897 11.9488C15.8632 11.9829 15.9367 12 16.0011 12C16.2676 12 16.5066 11.7783 16.4514 11.5053L16.0103 9.14286L17.8576 7.47121C18.1333 7.21535 17.9862 6.78891 17.6094 6.73774ZM3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H6C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7L3 7ZM2 12C2 11.4477 2.44772 11 3 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H3C2.44772 13 2 12.5523 2 12Z" fillRule="evenodd"/>
    </svg>
  );
}
IconVerification.defaultProps = {
  className: ''
};
IconVerification.propTypes = {
  className: PropTypes.string
};
