import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { useSelector } from 'react-redux';
import { Router } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import AppContextProvider from './contexts';
import pages from './pages';
import { router } from './utils/routeBuilder';
import { checkExpireTime, clearStorages, getToken } from './utils/storage';
import Base from './components/layouts/PageBase/Base';

const noAuthRoutes = ['/login', '/forgot-password', '/reset-password'];
const noAuth = RegExp(noAuthRoutes.join('|')).exec(location.pathname);
const token = getToken();

if (!token && !noAuth) {
  sessionStorage.setItem('redirect', location.pathname);
  location.replace('/admin/login');
} else if (checkExpireTime() && !noAuth) {
  clearStorages();
  location.replace('/admin/login');
} else if (getToken() && noAuth) {
  location.replace('/admin');
}

const App = ({ history }) => {
  const permissions = useSelector((state) => state.pageBase.dataDetailUser.permitions);

  return(
    <Router history={history}>
      <AppContextProvider>
        <Base>
          {/* <PageBase> */}
          <Switch>
            <Route component={pages.Login} exact path="/login" />
            <Route
              component={pages.ForgotPassword}
              exact
              path="/forgot-password"
            />
            <Route
              component={pages.ResetPassword}
              exact
              path="/reset-password"
            />
            <Route component={pages.firstLogin} exact path="/" />
            <Route component={pages.firstLogin} exact path="/first-login" />
            {
              router.map((route) => (
                permissions.map((permition) => (
                  (route.path === permition.path &&
                  route.elements.map(element =>
                    element.disabled ?
                      <Route
                        component={pages.Error403}
                        exact key={permition.permitionId} path={element.path} /> :
                      <Route
                        component={element.element}
                        exact key={permition.permitionId}
                        path={element.path} />)
                  )
                ))
              ))
            }
            <Route component={pages.Error404} />
          </Switch>
          {/* </PageBase> */}
        </Base>
      </AppContextProvider>
    </Router>
  );};

export default hot(App);

App.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};
