/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
export default function IconSettingFaq({ className }){
  return(
    <svg className={className} fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.8042 0C1.69678 0 0 1.69678 0 3.8042V14.1958C0 16.3032 1.69678 18 3.8042 18H14.1958C16.3032 18 18 16.3032 18 14.1958V3.8042C18 1.69678 16.3032 0 14.1958 0H3.8042ZM9 4.12354V4.12646C9.82399 4.12646 10.5 4.80252 10.5 5.62646C10.5 6.32051 10.0186 6.90374 9.375 7.07227V8.62646H13.125V10.9292C13.7686 11.0978 14.25 11.6825 14.25 12.3765C14.25 13.2005 13.574 13.8765 12.75 13.8765C11.926 13.8765 11.25 13.2005 11.25 12.3765C11.25 11.6825 11.7314 11.0978 12.375 10.9292V9.37646H9V9.36768H5.625V10.9263C6.26858 11.0949 6.75 11.6796 6.75 12.3735C6.75 13.1976 6.07399 13.8735 5.25 13.8735C4.42601 13.8735 3.75 13.1976 3.75 12.3735C3.75 11.6796 4.23142 11.0949 4.875 10.9263V8.61768H8.625V7.06934C7.98142 6.90081 7.5 6.31758 7.5 5.62354C7.5 4.79959 8.17601 4.12354 9 4.12354Z" />
    </svg>

  );
}
IconSettingFaq.defaultProps = {
  className: ''
};
IconSettingFaq.propTypes = {
  className: PropTypes.string
};
