import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCgSe6odkXqddW638r2zqycybQwcCBejns',
  authDomain: 'merchant-apps-pertamina.firebaseapp.com',
  projectId: 'merchant-apps-pertamina',
  storageBucket: 'merchant-apps-pertamina.appspot.com',
  messagingSenderId: '125478743265',
  appId: '1:125478743265:web:fab0b4024c6415d8675ba0',
  measurementId: 'G-TTV8E60J73',
};

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();

export { analytics };
