export const actionTypes = {
  FAILED: 'notification/failed',
  LOADING: 'notification/loading',
  SUCCESS: 'notification/success',
  FILTER: 'notification/filter',
  MESSAGE: 'notification/message',
  FORM: 'notification/form',
};

export const notificationType = {
  1: 'text-image',
  2: 'text',
  3: 'image',
};


export const notificationTypeNumber = {
  'text-image': '1',
  'text': '2',
  'image': '3',
};

