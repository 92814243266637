import { FAILED, LOADING, SUCCESS } from './constants';

export const initialState = {
  dataTermCondition: {},
  isLoadingTermCondition: false,
  messageTermCondition: ''
};

export default function reducer(state = initialState, action) {
  const { type, data, isLoading, message, key } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        [`data${key}`]: initialState[`data${key}`],
        [`isLoading${key}`]: false,
        [`message${key}`]: message,
      };
    case LOADING:
      return {
        ...state,
        [`isLoading${key}`]: isLoading,
        [`message${key}`]: '',
      };
    case SUCCESS:
      return {
        ...state,
        [`data${key}`]: data,
        [`isLoading${key}`]: false,
        [`message${key}`]: '',
      };
    default:
      return state;
  }
}
